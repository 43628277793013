(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileDetailController', ProfileDetailController);

    ProfileDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$http', '$translate', 'previousState', 'entity', 'Profile', 'Customer', 'DataUtils', 'CustomerStatusLogs'];

    function ProfileDetailController($scope, $rootScope, $stateParams, $http, $translate, previousState, entity, Profile, Customer, DataUtils, CustomerStatusLogs) {
        var vm = this;

        vm.profile = entity;
        vm.previousState = previousState.name;
        vm.save = save;
        vm.getCustomerStatusLogsByCustomerId = getCustomerStatusLogsByCustomerId;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:profileUpdate', function (event, result) {
            vm.profile = result;
        });

        $scope.$on('$destroy', unsubscribe);
        getCustomerStatusLogsByCustomerId();

        vm.dateOfBirth = new Date(vm.profile.customerDateOfBirth);

        loadNationalities();

        function save() {
            vm.customer = {
                id: vm.profile.customerId,
                fullName: vm.profile.customerFullName,
                status: vm.profile.customerStatus,
                dateOfBirth: vm.dateOfBirth,
                nationality: vm.profile.customerNationality,
                gender: vm.profile.customerGender
            };
            vm.isSaving = true;
            if (vm.profile.customerId !== null) {
                Customer.update(vm.customer, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            vm.profile.customerFullName = result.fullName;
            vm.profile.customerStatus = result.status;
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function getCustomerStatusLogsByCustomerId() {
            CustomerStatusLogs.query({customerId: vm.profile.customerId}, function (data) {
                vm.customerStatusLogs = data;
            });
        }

        function loadNationalities() {
            $http.get('i18n/' + $translate.use() + '/nationalityList.json').success(function (nationalities) {
                vm.nationalities = nationalities;
            });
        }
    }
})();
