(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SegmentFieldOptionDetailController', SegmentFieldOptionDetailController);

    SegmentFieldOptionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'SegmentFieldOption'];

    function SegmentFieldOptionDetailController($scope, $rootScope, $stateParams, previousState, entity, SegmentFieldOption) {
        var vm = this;

        vm.segmentFieldOption = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:segmentFieldOptionUpdate', function(event, result) {
            vm.segmentFieldOption = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
