(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('google-auth', {
            parent: 'app',
            url: '/google-auth',
            data: {
                authorities: []
            },
            views: {
                'google-auth@': {
                    templateUrl: 'app/layouts/google-auth/google-auth.html',
                    controller: 'GoogleAuthController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('error');
                    $translatePartialLoader.addPart('googleAuth');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
