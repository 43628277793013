(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('DepositProfileRedirectController', DepositProfileRedirectController);

    DepositProfileRedirectController.$inject = ['$stateParams', '$location', 'ProfileIdByPhone'];

    function DepositProfileRedirectController($stateParams, $location, ProfileIdByPhone) {


        ProfileIdByPhone.get({phoneNumber: $stateParams.phone}, onSuccess);

        function onSuccess(data) {
            $location.path("profile/" + data.profileId);
        }
    }
})();
