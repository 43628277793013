(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('NewsDialogController', NewsDialogController);

    NewsDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'DataUtils', 'entity', 'News'];

    function NewsDialogController($timeout, $scope, $uibModalInstance, DataUtils, entity, News) {
        var vm = this;

        vm.news = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.isAppsOnForNews = isAppsOnForNews;

        editorOptions();
        toggleSwitchApps();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            getSwitchedOnAppsValue();
            if (vm.news.id !== null) {
                News.update(vm.news, onSaveSuccess, onSaveError);
            } else {
                News.save(vm.news, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:newsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.pubDate = false;
        vm.datePickerOpenStatus.lastEdited = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function editorOptions() {
            $scope.options = {
                height: 400,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
                    ['color', ['color']],
                    ['fontsize', ['fontsize']],
                    ['picture', ['picture']],
                    ['video', ['video']],
                    ['fontface', ['fontname']],
                    ['insert', ['link']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']]
                ]
            };
        }

        function isAppsOnForNews(lang) {
            if (lang === "uz")
                return vm.payGramUzb;
            else if (lang === "en")
                return vm.payGramEng;
            else if (lang === "ru")
                return vm.payGramRus;
            else if (lang === "tj")
                return vm.payGramTj;
        }

        function toggleSwitchApps() {
            vm.payGramUzb = toggleSwitchForApp(2);

            vm.payGramEng = toggleSwitchForApp(16);

            vm.payGramRus = toggleSwitchForApp(128);

            vm.payGramTj = toggleSwitchForApp(512);
        }

        function toggleSwitchForApp(value) {
            return (vm.news.shouldDisplay & value) > 0;
        }

        function getSwitchedOnAppsValue() {
            vm.news.shouldDisplay = 0;
            sumShouldDisplay(vm.payGramUzb, 2);

            sumShouldDisplay(vm.payGramEng, 16);

            sumShouldDisplay(vm.payGramRus, 128);

            sumShouldDisplay(vm.payGramTj, 512);
        }

        function sumShouldDisplay(isAppOn, value) {
            if (isAppOn) {
                vm.news.shouldDisplay += value;
            }
        }
    }
})();
