(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsTransferSender', StatsTransferSender);

    StatsTransferSender.$inject = ['$resource', 'DateUtils'];

    function StatsTransferSender($resource) {
        var resourceUrl = 'api/stats-transfer-sender-count';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
