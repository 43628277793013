(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$scope', '$state', 'Auth', 'Principal', 'ProfileService'];

    function SidebarController($scope, $state, Auth, Principal, ProfileService) {
        $scope.$on('$viewContentLoaded', function () {
            setTimeout(function () {
                Layout.initSidebar($state);
                Layout.initContent();
                Layout.initFooter();
            }, 0)
        });

        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });
    }
})();
