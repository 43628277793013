(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SmsRussiaBalance', SmsRussiaBalance)
        .factory('SmsRussiaLimit', SmsRussiaLimit);

    SmsRussiaBalance.$inject = ['$resource'];

    function SmsRussiaBalance ($resource) {
        var resourceUrl =  'api/sms-ru/balance';
        return $resource(resourceUrl, {}, {
        });
    }

    SmsRussiaLimit.$inject = ['$resource'];

    function SmsRussiaLimit ($resource) {
        var resourceUrl =  'api/sms-ru/limit';
        return $resource(resourceUrl, {}, {
        });
    }

})();
