(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('LanguageLocalizationController', LanguageLocalizationController);

    LanguageLocalizationController.$inject = ['$state', 'LanguageLocalization', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function LanguageLocalizationController($state, LanguageLocalization, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = {
            code: pagingParams.code,
            messageRu: pagingParams.messageRu,
            messageUz: pagingParams.messageUz,
            messageEn: pagingParams.messageEn
        };
        if (!pagingParams.firstRun) {
            loadAll();
        }

        function loadAll () {
            LanguageLocalization.query({
                code: pagingParams.code,
                messageRu: pagingParams.messageRu,
                messageUz: pagingParams.messageUz,
                messageEn: pagingParams.messageEn,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.languageLocalizations = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                code: vm.searchQuery.code,
                messageRu: vm.searchQuery.messageRu,
                messageUz: vm.searchQuery.messageUz,
                messageEn: vm.searchQuery.messageEn,
                firstRun: false
            });
        }
        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.searchQuery = {
                code: null,
                messageRu: null,
                messageUz: null,
                messageEn: null
            };
            vm.transition();
        }
    }
})();
