(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('TransactionStatus', TransactionStatus);

    TransactionStatus.$inject = ['$resource', 'DateUtils'];

    function TransactionStatus ($resource, DateUtils) {
        var resourceUrl =  'api/transactions/change_status';

        return $resource(resourceUrl, {}, {
            'update': { method:'PUT' }
        });
    }
})();
