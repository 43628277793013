/**
 * Created by Khasan on 6/12/2017.
 */
(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('stats-profit', {
                parent: 'entity',
                url: '/stats-profit',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'global.menu.statistics.profit'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats-profit/stats-profit.html',
                        controller: 'StatsProfitController',
                        controllerAs: 'vm'
                    }
                },

                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('statsMerchants');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
/**
 * Created by user on 6/14/2017.
 */
