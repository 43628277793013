(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('segment-field', {
            parent: 'entity',
            url: '/segment-field?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.segmentField.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/segment-field/segment-fields.html',
                    controller: 'SegmentFieldController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                label: null,
                providerName: null,
                providerSegment: null,
                name: null,
                type: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        label: $stateParams.label,
                        providerName: $stateParams.providerName,
                        providerSegment: $stateParams.providerSegment,
                        name: $stateParams.name,
                        type: $stateParams.type
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('segmentField');
                    $translatePartialLoader.addPart('fieldType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('segment-field-detail', {
            parent: 'segment-field',
            url: '/segment-field/{id}',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.segmentField.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/segment-field/segment-field-detail.html',
                    controller: 'SegmentFieldDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('segmentField');
                    $translatePartialLoader.addPart('fieldType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'SegmentField', function($stateParams, SegmentField) {
                    return SegmentField.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'segment-field',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('segment-field-detail.edit', {
            parent: 'segment-field-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field/segment-field-dialog.html',
                    controller: 'SegmentFieldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SegmentField', function(SegmentField) {
                            return SegmentField.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('segment-field.new', {
            parent: 'segment-field',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field/segment-field-dialog.html',
                    controller: 'SegmentFieldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                label: null,
                                name: null,
                                type: null,
                                value: null,
                                minLength: null,
                                maxLength: null,
                                min: null,
                                max: null,
                                format: null,
                                pattern: null,
                                hidden: false,
                                required: false,
                                prefixesJson: null,
                                position: null,
                                segmentFieldOptionId: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('segment-field', null, { reload: 'segment-field' });
                }, function() {
                    $state.go('segment-field');
                });
            }]
        })
        .state('segment-field.edit', {
            parent: 'segment-field',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field/segment-field-dialog.html',
                    controller: 'SegmentFieldDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SegmentField', function(SegmentField) {
                            return SegmentField.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('segment-field', null, { reload: 'segment-field' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('segment-field.delete', {
            parent: 'segment-field',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field/segment-field-delete-dialog.html',
                    controller: 'SegmentFieldDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SegmentField', function(SegmentField) {
                            return SegmentField.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('segment-field', null, { reload: 'segment-field' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
