(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderSegmentDetailController', ProviderSegmentDetailController);

    ProviderSegmentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ProviderSegment', 'Provider'];

    function ProviderSegmentDetailController($scope, $rootScope, $stateParams, previousState, entity, ProviderSegment, Provider) {
        var vm = this;

        vm.providerSegment = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:providerSegmentUpdate', function(event, result) {
            vm.providerSegment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
