/**
 * Created by Gayratjon Rayimjonov on 5/24/2017.
 */
(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsDaily', StatsDaily);

    StatsDaily.$inject = ['$resource', 'DateUtils'];


    function StatsDaily ($resource, DateUtils) {
        var resourceUrl =  'api/stats-daily';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.fromDate = DateUtils.convertLocalDateFromServer(data.fromDate);
                    }
                    return data;
                }
            }
        });
    }
})();
