(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderController', ProviderController);

    ProviderController.$inject = ['$state', 'Provider', 'ProviderCategory', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function ProviderController($state, Provider, ProviderCategory, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.providerCategories = ProviderCategory.getAsList();
        vm.loadAll = loadAll;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            name: pagingParams.name,
            categoryId: pagingParams.categoryId,
            gateway: pagingParams.gateway,
            status: pagingParams.status,
            providerId: pagingParams.providerId
        };
        if (!pagingParams.firstRun) {
            loadAll();
        }

        function loadAll () {
            Provider.query({
                name: pagingParams.name,
                categoryId: pagingParams.categoryId,
                gateway: pagingParams.gateway,
                status: pagingParams.status,
                providerId: pagingParams.providerId,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.providers = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                name: vm.searchQuery.name,
                categoryId: vm.searchQuery.categoryId,
                gateway: vm.searchQuery.gateway,
                status: vm.searchQuery.status,
                providerId: vm.searchQuery.providerId,
                firstRun: false
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = pagingParams.predicate;
            vm.reverse = pagingParams.ascending;
            vm.searchQuery = {
                name: null,
                categoryId: null,
                gateway: null,
                status: null,
                providerId: null
            };
            vm.transition();
        }
    }
})();
