(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('profile', {
                parent: 'entity',
                url: '/profile?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_PAYGRAM'],
                    pageTitle: 'global.menu.user.information'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profiles.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    field: null,
                    fromDate: null,
                    toDate: null,
                    status: null,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            field: $stateParams.field,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            status: $stateParams.status,
                            firstRun: $stateParams.firstRun
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('profile-detail', {
                parent: 'entity',
                url: '/profile/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_PAYGRAM'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-detail.html',
                        controller: 'ProfileDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-transaction', {
                parent: 'entity',
                url: '/profile/{id}/transactions?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_CUSTOMER_SUPPORT_PAYGRAM'],
                    pageTitle: 'bozorakaAdminApp.profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-transaction.html',
                        controller: 'ProfileTransactionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    pageTranHist: {
                        value: '1',
                        squash: true
                    },
                    sortTranHist: {
                        value: 'pubDate,desc',
                        squash: true
                    },
                    pageTransfer: {
                        value: '1',
                        squash: true
                    },
                    sortTransfer: {
                        value: 'id,desc',
                        squash: true
                    },
                    pageTkbCard: {
                        value: '1',
                        squash: true
                    },
                    sortTkbCard: {
                        value: 'id,desc',
                        squash: true
                    },
                    activeTab: 'tab_1'
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageTranHist: PaginationUtil.parsePage($stateParams.pageTranHist),
                            sortTranHist: $stateParams.sortTranHist,
                            predicateTranHist: PaginationUtil.parsePredicate($stateParams.sortTranHist),
                            ascendingTranHist: PaginationUtil.parseAscending($stateParams.sortTranHist),
                            pageTransfer: PaginationUtil.parsePage($stateParams.pageTransfer),
                            sortTransfer: $stateParams.sortTransfer,
                            predicateTransfer: PaginationUtil.parsePredicate($stateParams.sortTransfer),
                            ascendingTransfer: PaginationUtil.parseAscending($stateParams.sortTransfer),
                            pageTkbCard: PaginationUtil.parsePage($stateParams.pageTkbCard),
                            sortTkbCard: $stateParams.sortTkbCard,
                            predicateTkbCard: PaginationUtil.parsePredicate($stateParams.sortTkbCard),
                            ascendingTkbCard: PaginationUtil.parseAscending($stateParams.sortTkbCard),
                            activeTab: $stateParams.activeTab
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('tkbTransfer');
                        $translatePartialLoader.addPart('tkbCard');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }]
                    ,
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-transaction',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile.new', {
                parent: 'profile',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    accountNo: null,
                                    balance: null,
                                    totalMileage: null,
                                    registrationDate: null,
                                    isAgent: false,
                                    isMango: false,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('profile');
                    });
                }]
            })
            .state('profile.edit', {
                parent: 'profile',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-dialog.html',
                        controller: 'ProfileDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile.delete', {
                parent: 'profile',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-delete-dialog.html',
                        controller: 'ProfileDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile', null, {reload: 'profile'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.editStatus', {
                parent: 'profile-detail',
                url: '/status/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-status-dialog.html',
                        controller: 'ProfileStatusDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        resolve: {
                            entity: ['Profile', function (Profile) {
                                return Profile.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-detail.changePhone', {
                    parent: 'profile-detail',
                    url: '/phone-number/edit',
                    data: {
                        authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                    },
                    onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/profile/profile-change-phone-dialog.html',
                            controller: 'ProfileChangePhoneDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                entity: ['Profile', function (Profile) {
                                    return Profile.get({id: $stateParams.id}).$promise;
                                }]
                            }
                        }).result.then(function () {
                            $state.go('^', {}, {reload: false});
                        }, function () {
                            $state.go('^');
                        });
                    }]
            })
            .state('profile-notification', {
                parent: 'entity',
                url: '/profile/{id}/notifications?page&sort',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY'],
                    pageTitle: 'profile.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/profile/profile-notification.html',
                        controller: 'ProfileNotificationController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    sortNotification: {
                        value: 'createdDate,desc',
                        squash: true
                    },
                    pageNotification: {
                        value: 1,
                        squash: true
                    },
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            pageNotification: PaginationUtil.parsePage($stateParams.pageNotification),
                            sortNotification: $stateParams.sortNotification,
                            predicateNotification: PaginationUtil.parsePredicate($stateParams.sortNotification),
                            ascendingNotification: PaginationUtil.parseAscending($stateParams.sortNotification),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('profile');
                        $translatePartialLoader.addPart('customerNotification');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Profile', function ($stateParams, Profile) {
                        return Profile.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'profile-notification',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('profile-notification.edit', {
                parent: 'profile-notification',
                url: '/{notificationId}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-dialog.html',
                        controller: 'ProfileNotificationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }],
                            languages: ['FirebaseToken', function (FirebaseToken) {
                                return FirebaseToken.getByProfile({profileId: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-notification.view', {
                parent: 'profile-notification',
                url: '/{notificationId}/view',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-detail.html',
                        controller: 'ProfileNotificationDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('profile-notification.new', {
                parent: 'profile-notification',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-dialog.html',
                        controller: 'ProfileNotificationDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                $translatePartialLoader.addPart('notificationStatus');
                                return $translate.refresh();
                            }],
                            entity: function () {
                                return {
                                    title: null,
                                    message: null,
                                    language: null,
                                    collapseKey: null,
                                    eventType: 'PROMO_CODE',
                                    url: null,
                                    readStatus: false,
                                    status: 'PENDING',
                                    deleted: false,
                                    pushDate: null,
                                    expiryDate: null,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    id: null
                                };
                            },
                            languages: ['FirebaseToken', function (FirebaseToken) {
                                return FirebaseToken.getByProfile({profileId: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('profile-notification');
                    });
                }]
            })
            .state('profile-notification.delete', {
                parent: 'profile-notification',
                url: '/{notificationId}/delete',
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/profile/profile-notification-delete-dialog.html',
                        controller: 'ProfileNotificationDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        data: {
                            authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_MARKET_MANAGER', 'ROLE_CUSTOMER_SUPPORT_BOZORAKA', 'ROLE_CUSTOMER_SUPPORT_MOBPAY']
                        },
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customerNotification');
                                return $translate.refresh();
                            }],
                            entity: ['CustomerNotification', function (CustomerNotification) {
                                return CustomerNotification.get({id: $stateParams.notificationId}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('profile-notification', null, {reload: 'profile-notification'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
