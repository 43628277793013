(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CustomerStatusLogController', CustomerStatusLogController);

    CustomerStatusLogController.$inject = ['$state', '$window', 'CustomerStatusLog', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function CustomerStatusLogController($state, $window, CustomerStatusLog, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.clear = clear;
        vm.openProfile = openProfile;
        vm.searchQuery = {
            phone: pagingParams.phone,
            status: pagingParams.status,
            changeReason: pagingParams.changeReason
        };
        loadAll();

        function loadAll () {
            CustomerStatusLog.query({
                phone: vm.searchQuery.phone,
                status: vm.searchQuery.status,
                changeReason: (vm.searchQuery.changeReason === null ? '' : vm.searchQuery.changeReason),
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerStatusLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                phone: vm.searchQuery.phone,
                status: vm.searchQuery.status,
                changeReason: vm.searchQuery.changeReason
            });
        }

        function search() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                phone: null,
                status: null,
                changeReason: ''
            };
            vm.transition();
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url, '_blank');
            }
        }
    }
})();
