(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('stats-transfer-sender', {
                parent: 'entity',
                url: '/stats-transfer-sender?page&search',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN'],
                    pageTitle: 'global.menu.statistics.transferSenderCount'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats-transfer-sender/stats-transfer-sender.html',
                        controller: 'StatsTransferSenderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'count(tkb.id),desc',
                        squash: true
                    },
                    userPhone: null,
                    count: 2,
                    amount: 0,
                    fromDate: null,
                    toDate: null,
                    fromZonedDate: null,
                    toZonedDate: null,
                    firstRun: true
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            userPhone: $stateParams.userPhone,
                            count: $stateParams.count,
                            amount: $stateParams.amount,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            fromZonedDate: $stateParams.fromZonedDate,
                            toZonedDate: $stateParams.toZonedDate,
                            firstRun: $stateParams.firstRun,
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('transaction');
                        $translatePartialLoader.addPart('tkbTransfer');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
    }

})();
