(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbPaymentDetailController', TkbPaymentDetailController);

    TkbPaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'TkbPayment', 'Customer', 'Transaction'];

    function TkbPaymentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, TkbPayment, Customer, Transaction) {
        var vm = this;

        vm.tkbPayment = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:tkbPaymentUpdate', function(event, result) {
            vm.tkbPayment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
