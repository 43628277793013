(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('BannerImageDialogController', BannerImageDialogController);

    BannerImageDialogController.$inject = ['$timeout', '$scope', 'DataUtils', '$uibModalInstance', '$q', 'entity', 'BannerImage', 'ImageStorage'];

    function BannerImageDialogController ($timeout, $scope, DataUtils, $uibModalInstance, $q, entity, BannerImage, ImageStorage) {
        var vm = this;

        vm.bannerImage = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.bannerImage.id !== null) {
                BannerImage.update(vm.bannerImage, onSaveSuccess, onSaveError);
            } else {
                BannerImage.save(vm.bannerImage, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('bozorakaAdminApp:bannerImageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.setData = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        vm.bannerImage.imageStorageName = $file.name;
                        vm.bannerImage.data = base64Data;
                        vm.bannerImage.dataContentType = $file.type;

                    });
                });
            }
        };


    }
})();
