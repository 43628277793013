(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('NotificationDialogController', NotificationDialogController);

    NotificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Notification', 'AllNews', '$translate', 'SentNotifications'];

    function NotificationDialogController($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Notification, AllNews, $translate, SentNotifications) {
        var vm = this;

        vm.notification = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.lang = $translate.use();
        vm.news = AllNews.query();

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.notification.shouldOverride == null || vm.notification.shouldOverride == undefined){
                vm.notification.shouldOverride = false;
            }
            if (vm.notification.id !== null) {
                Notification.update(vm.notification, onSaveSuccess, onSaveError);
            } else {
                Notification.save(vm.notification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:notificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.pushDate = false;
        vm.datePickerOpenStatus.expiryDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.setData = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function (base64Data) {
                    $scope.$apply(function () {
                        if (vm.notification.bannerUrl == null || vm.notification.bannerUrl == undefined) {
                            vm.notification.bannerUrl = $file.name;
                        }
                        vm.notification.data = base64Data;
                        vm.notification.dataContentType = $file.type;

                    });
                });
            }
        };

        vm.isChecked = function (event) {
            if (event) {
                SentNotifications.query({}, function (data) {
                    vm.notifications = data;
                });
            } else {
                vm.notifications = null;
            }
        };

        vm.selectedNotification = function (notification) {
            Notification.get({id: notification.id}, function (data) {
                data.id = null;
                data.shouldOverride = true;
                vm.notification = data;
            });

        }
    }
})();
