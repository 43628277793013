(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .filter('handledTime', handledTime);

    function handledTime() {
        return handledTimeFilter;

        function handledTimeFilter(date) {
            if (!isNaN(date)) {
                var time = moment.duration(date);
                var formattedTime = "";

                if (time.days() > 0) {
                    formattedTime += time.days() + "days ";
                }

                if (time.hours() > 0) {
                    formattedTime += ((time.hours() >= 10) ? time.hours() : "0" + time.hours()) + ":";
                }

                formattedTime += ((time.minutes() >= 10) ? time.minutes() : "0" + time.minutes()) + ":";
                formattedTime += (time.seconds() >= 10) ? time.seconds() : "0" + time.seconds();
                return formattedTime;
            } else {
                return "NaN";
            }
        }
    }
})();
