(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('CustomerNotificationController', CustomerNotificationController);

    CustomerNotificationController.$inject = ['$state', '$filter', 'CustomerNotification', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function CustomerNotificationController($state, $filter, CustomerNotification, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;


        vm.search = search;
        vm.clear = clear;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = {
            phone: pagingParams.phone,
            langKey: pagingParams.langKey,
            eventType: pagingParams.eventType,
            status: pagingParams.status,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();
        loadAll();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function loadAll () {
            CustomerNotification.query({
                phone: vm.searchQuery.phone,
                langKey: vm.searchQuery.langKey,
                eventType: vm.searchQuery.eventType,
                status: vm.searchQuery.status,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customerNotifications = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                phone: vm.searchQuery.phone,
                langKey: vm.searchQuery.langKey,
                eventType: vm.searchQuery.eventType,
                status: vm.searchQuery.status,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                phone: "",
                langKey: null,
                eventType: null,
                status: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }
    }
})();
