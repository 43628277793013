(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbCardDeleteController',TkbCardDeleteController);

    TkbCardDeleteController.$inject = ['$uibModalInstance', 'entity', 'TkbCard', 'AlertService'];

    function TkbCardDeleteController($uibModalInstance, entity, TkbCard, AlertService) {
        var vm = this;

        vm.tkbCard = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TkbCard.delete({id: id},
                onSuccess, onError);
        }
        function onSuccess(){
                $uibModalInstance.close(true);
        }
        function onError(error) {
            AlertService.error(error.data.errorDescription);
        }
    }
})();
