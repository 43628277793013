(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PlayIntegrityLogController', PlayIntegrityLogController);

    PlayIntegrityLogController.$inject = ['$state', 'DataUtils', 'PlayIntegrityLog', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PlayIntegrityLogController($state, DataUtils, PlayIntegrityLog, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.getShortJson = getShortJson;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.searchQuery = {
            phone: pagingParams.phone,
            status: pagingParams.status,
            endpoint: pagingParams.endpoint,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }


        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.searchQuery = {
                phone: null,
                endpoint: null,
                status: null,
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            vm.transition();
        }

        loadAll();

        function loadAll () {
            PlayIntegrityLog.query({
                phone: vm.searchQuery.phone,
                status: vm.searchQuery.status,
                endpoint: vm.searchQuery.endpoint,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.playIntegrityLogs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                phone: vm.searchQuery.phone,
                status: vm.searchQuery.status,
                endpoint: vm.searchQuery.endpoint,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                search: vm.currentSearch
            });
        }

        function getShortJson(responseString) {
            if (responseString === null) {
                return "";
            }
            var responseJson = JSON.parse(responseString);
            var result = "";
            if (responseJson.tokenPayloadExternal !== undefined && responseJson.tokenPayloadExternal.accountDetails !== undefined && responseJson.tokenPayloadExternal.accountDetails.appLicensingVerdict !== undefined) {
                result = result + "appLicensingVerdict:" + responseJson.tokenPayloadExternal.accountDetails.appLicensingVerdict;
            }
            if (responseJson.tokenPayloadExternal !== undefined && responseJson.tokenPayloadExternal.appIntegrity !== undefined && responseJson.tokenPayloadExternal.appIntegrity.appRecognitionVerdict !== undefined) {
                result = result + " appRecognitionVerdict:" + responseJson.tokenPayloadExternal.appIntegrity.appRecognitionVerdict;
            }
            if (responseJson.tokenPayloadExternal !== undefined && responseJson.tokenPayloadExternal.deviceIntegrity !== undefined && responseJson.tokenPayloadExternal.deviceIntegrity.deviceRecognitionVerdict !== undefined) {
                result = result + " deviceRecognitionVerdict:" + responseJson.tokenPayloadExternal.deviceIntegrity.deviceRecognitionVerdict[0];
            }
            return result;
        }
    }
})();
