(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('VpPaymentDetailController', VpPaymentDetailController);

    VpPaymentDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'VpPayment', 'Transaction', 'Customer'];

    function VpPaymentDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, VpPayment, Transaction, Customer) {
        var vm = this;

        vm.vpPayment = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:vpPaymentUpdate', function(event, result) {
            vm.vpPayment = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
