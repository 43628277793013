(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GoogleAuthDialogController', GoogleAuthDialogController);

    GoogleAuthDialogController.$inject = ['$timeout', '$window', '$location', '$uibModalInstance', 'GoogleAuth', 'Auth'];

    function GoogleAuthDialogController ($timeout, $window, $location, $uibModalInstance, GoogleAuth, Auth) {
        var vm = this;
        vm.passwordStatus = -1;

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            var gAuthData = {
                newState: false,
                password: vm.userPassword
            };
            GoogleAuth.changeAuthKey(gAuthData, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            if (result.status) {
                vm.passwordStatus = 1;
                logout();
            } else {
                vm.passwordStatus = 0;
            }
        }

        function onSaveError (error) {
            vm.isSaving = false;
            logout();
        }

        function logout() {
            $location.path('/login').replace();
            Auth.logout();
            $window.location.reload();
        }

    }
})();
