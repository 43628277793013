(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TransactionAsyncTaskDetailController', TransactionAsyncTaskDetailController);

    TransactionAsyncTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TransactionAsyncTask', 'Transaction'];

    function TransactionAsyncTaskDetailController($scope, $rootScope, $stateParams, previousState, entity, TransactionAsyncTask, Transaction) {
        var vm = this;

        vm.transactionAsyncTask = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:transactionAsyncTaskUpdate', function(event, result) {
            vm.transactionAsyncTask = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
