(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ConfigController', ConfigController);

    ConfigController.$inject = ['Config'];

    function ConfigController(Config) {

        var vm = this;
        vm.save = save;

        vm.configs = [];
        vm.allConfigs = {}

        loadAll();

        function loadAll() {
            Config.query(function (result) {
                vm.configs = result;
                for (var i = 0; i < vm.configs.length; i++) {
                    vm.allConfigs[vm.configs[i].name] = vm.configs[i];
                }
                vm.searchQuery = null;
            });
        }

        function save(name) {
            vm.isSaving = true;
            if (vm.allConfigs[name].id !== null) {
                Config.update(vm.allConfigs[name], onSaveSuccess, onSaveError);
            } else {
                Config.save(vm.allConfigs[name], onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            loadAll();
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
