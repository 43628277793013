(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('GoogleAuth', GoogleAuth);

    GoogleAuth.$inject = ['$resource'];

    function GoogleAuth($resource) {
        var resourceUrl = 'api/google-authenticator';

        return $resource(resourceUrl, {}, {
            'generateAuthKey': {
                url: resourceUrl + '/generate',
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'verifyPassword': {
                url: resourceUrl + '/verify-password',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'verifyAuthKey': {
                url: resourceUrl + '/verify',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'changeAuthKey': {
                url: resourceUrl + '/change-state',
                method: 'POST',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            }
        });
    }
})();
