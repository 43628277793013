(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProfileNotification', ProfileNotification);

    ProfileNotification.$inject = ['$resource'];

    function ProfileNotification($resource) {
        var resourceUrl = 'api/profiles/:profileId/notifications';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

})();
