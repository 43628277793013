(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('serviceSelection', serviceSelection);

    var services = {
        UNKNOWN: 'ALL',
        SERVICE_KASH_PAYNET: 'Paynet',
        SERVICE_PAYMENT: 'Payment',
        SERVICE_HERMES_PAYMENT: 'Hermes',
        SERVICE_UZB_PHONE: 'UzTel',
        SERVICE_UZB_INTERNET: 'UzInt',
        SERVICE_VOIP: 'VoIP',
        SERVICE_RUS_PHONE: 'RuTel',
        SERVICE_KAZ_PHONE: 'KzTel',
        SERVICE_KGZ_PHONE: 'KgTel',
        SERVICE_TJK_PHONE: 'TjTel',
        SERVICE_UKR_PHONE: 'UkTel',
        SERVICE_ARM_PHONE: 'AmTel',
        SERVICE_OK_CASH: 'OkCash',
        SERVICE_UZB_UTILITY: 'UzbUtil',
        SERVICE_EXPRESS_PAY: 'ExpressPay',
        SERVICE_MOBILNIK: 'Mobilnik'
    };

    function serviceSelection() {
        var template =
            '<select class="form-control" name="serviceType" ng-model="vm.searchQuery.serviceType">' +
                '<option value=""></option>' +
            '<option ng-repeat="(key, value) in ownVm.serviceMap" value="{{key}}">{{value}}</option>' +
            '</select>'

        var directive = {
            restrict: 'EA',
            template: template,
            controller: ServiceSelectionController,
            controllerAs: 'ownVm',
            bindToController: true
        };

        return directive;
    }

    ServiceSelectionController.$inject = ['Principal'];

    function ServiceSelectionController(Principal) {
        var ownVm = this;
        ownVm.serviceMap = {};
        if (Principal.hasAnyAuthority(['ROLE_CUSTOMER_SUPPORT_PAYGRAM'])) {
            addServices([
                'UNKNOWN',
                'SERVICE_UZB_PHONE',
                'SERVICE_UZB_INTERNET',
                'SERVICE_KASH_PAYNET',
                'SERVICE_PAYMENT',
                'SERVICE_HERMES_PAYMENT',
                'SERVICE_VOIP',
                'SERVICE_RUS_PHONE',
                'SERVICE_KAZ_PHONE',
                'SERVICE_KGZ_PHONE',
                'SERVICE_TJK_PHONE',
                'SERVICE_UKR_PHONE',
                'SERVICE_OK_CASH',
                'SERVICE_EXPRESS_PAY',
                'SERVICE_MOBILNIK'
            ]);
        } else if (Principal.hasAnyAuthority(['ROLE_ADMIN', 'ROLE_MANAGER'])) {
            // Remove or comment allServices, if you want to add ROLES manually
            var allServices = Object.keys(services);
            addServices(allServices);
        }

        function addServices(serviceKeys) {
            for (var i = 0; i < serviceKeys.length; i++) {
                var serviceKey = serviceKeys[i];
                ownVm.serviceMap[serviceKey] = services[serviceKey];
            }
        }
    }
})();
