(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('AppController', AppController);

    AppController.$inject = ['$scope', '$location', '$state', '$translatePartialLoader', '$translate', 'Principal'];

    function AppController($scope, $location, $state, $translatePartialLoader, $translate, Principal) {
        $scope.loginCss = "";

        getAccount();

        function getAccount() {
            Principal.identity(true).then(function (account) {
                $scope.isAuthenticated = Principal.isAuthenticated();
                $scope.isGoogleAuthEnabled = Principal.isGoogleAuthEnabled();
                if (!$scope.isGoogleAuthEnabled) {
                    $state.go('google-auth');
                }
                if (!$scope.isAuthenticated) {
                    $scope.loginCss = "login";
                    $translatePartialLoader.addPart('login');
                    $translate.refresh();
                    $state.go('login');
                }
            });
        }
    }
})();
