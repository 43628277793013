(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileNotificationDetailController', ProfileNotificationDetailController);

    ProfileNotificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity'];

    function ProfileNotificationDetailController($scope, $rootScope, $stateParams, $uibModalInstance, entity) {
        var vm = this;

        vm.customerNotification = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:customerNotificationUpdate', function(event, result) {
            vm.customerNotification = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
