(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ExchangerateController', ExchangerateController);

    ExchangerateController.$inject = ['$scope', '$state', 'Exchangerate', 'AlertService'];

    function ExchangerateController ($scope, $state, Exchangerate, AlertService) {
        var vm = this;

        vm.save = save;
        vm.loadAll = loadAll;
        vm.exchangerate = null;
        vm.exchangeRates = {};

        loadAll();

        function loadAll () {
            Exchangerate.query({
                page: 0,
                size: vm.itemsPerPage
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.exchangerates = data;
                for (var i = 0; i < data.length; i++) {
                    vm.exchangeRates[data[i].name] = data[i];
                }
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function save (name) {
            vm.isSaving = true;
            if (vm.exchangeRates[name].id !== null) {
                Exchangerate.update(vm.exchangeRates[name], onSaveSuccess, onSaveError);
            } else {
                Exchangerate.save(vm.exchangeRates[name], onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.exchangeRates[result.name] = result;
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
