(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', '$timeout', '$location', '$window', 'Auth'];

    function LoginController ($rootScope, $state, $timeout, $location, $window, Auth) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.register = register;
        vm.rememberMe = true;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;
        vm.isOtpRequired = false;
        vm.otp = null;

        $timeout(function (){angular.element('#username').focus();});

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            // $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            event.preventDefault();
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                otp: vm.otp
            }).then(function (data) {
                vm.authenticationError = false;
                if (data.data.isTfaActive === undefined || !data.data.isTfaActive) {
                    $rootScope.$broadcast('authenticationSuccess');
                    $window.location.href = "/";
                } else {
                    vm.isOtpRequired = true;
                }
            }).catch(function () {
                vm.authenticationError = true;
            });
        }

        function register () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('register');
        }

        function requestResetPassword () {
            // $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
    }
})();
