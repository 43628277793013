(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('BotNotificationDeleteController',BotNotificationDeleteController);

    BotNotificationDeleteController.$inject = ['$uibModalInstance', 'entity', 'BotNotification'];

    function BotNotificationDeleteController($uibModalInstance, entity, BotNotification) {
        var vm = this;

        vm.botNotification = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            BotNotification.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
