(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('SegmentField', SegmentField)
        .factory('ProviderSegmentField', ProviderSegmentField);

    SegmentField.$inject = ['$resource', 'DateUtils'];

    function SegmentField ($resource, DateUtils) {
        var resourceUrl =  'api/segment-fields/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    ProviderSegmentField.$inject = ['$resource'];
    function ProviderSegmentField ($resource) {
        var resourceUrl =  'api/provider-segments/:id/segment-fields';
        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true}
        });
    }
})();
