(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('VpPayment', VpPayment)
        .factory('VpPaymentTotalAmount', VpPaymentTotalAmount);

    VpPayment.$inject = ['$resource', 'DateUtils'];

    function VpPayment ($resource, DateUtils) {
        var resourceUrl =  'api/vp-payments/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.date = DateUtils.convertDateTimeFromServer(data.date);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    VpPaymentTotalAmount.$inject = ['$resource'];

    function VpPaymentTotalAmount($resource) {
        var resourceUrl = 'api/vp-payments/total-amount';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

})();
