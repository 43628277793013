(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('MyIDController', MyIDController);

    MyIDController.$inject = ['$state', '$window', 'DataUtils', 'MyID', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function MyIDController($state, $window, DataUtils, MyID, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openFile = DataUtils.openFile;
        vm.byteSize = DataUtils.byteSize;
        vm.search = search;
        vm.clear = clear;
        vm.openProfile = openProfile;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            documentNumber: pagingParams.documentNumber,
            firstName: pagingParams.firstName,
            lastName: pagingParams.lastName,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        loadAll();

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url, '_blank');
            }
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                documentNumber: null,
                firstName: null,
                lastName: null,
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            vm.transition();
        }
        function loadAll () {
            MyID.query({
                userPhone: vm.searchQuery.userPhone,
                documentNumber: vm.searchQuery.documentNumber,
                firstName: vm.searchQuery.firstName,
                lastName: vm.searchQuery.lastName,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.myIDS = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                documentNumber: vm.searchQuery.documentNumber,
                firstName: vm.searchQuery.firstName,
                lastName: vm.searchQuery.lastName,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                search: vm.currentSearch
            });
        }
    }
})();
