(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('my-id', {
                parent: 'entity',
                url: '/my-id?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.myID.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/my-id/my-ids.html',
                        controller: 'MyIDController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    userPhone: null,
                    documentNumber: null,
                    firstName: null,
                    lastName: null,
                    fromDate: null,
                    toDate: null,
                    from: null,
                    to: null,
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            userPhone: $stateParams.userPhone,
                            documentNumber: $stateParams.documentNumber,
                            firstName: $stateParams.firstName,
                            lastName: $stateParams.lastName,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            from: $stateParams.from,
                            to: $stateParams.to
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('myID');
                        $translatePartialLoader.addPart('tkbCard');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('my-id-detail', {
                parent: 'my-id',
                url: '/my-id/{id}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.myID.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/my-id/my-id-detail.html',
                        controller: 'MyIDDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('myID');
                        $translatePartialLoader.addPart('gender');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'MyID', function ($stateParams, MyID) {
                        return MyID.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'my-id',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('my-id-detail.edit', {
                parent: 'my-id-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/my-id/my-id-dialog.html',
                        controller: 'MyIDDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['MyID', function (MyID) {
                                return MyID.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, {reload: false});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('my-id.new', {
                parent: 'my-id',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/my-id/my-id-dialog.html',
                        controller: 'MyIDDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    firstName: null,
                                    lastName: null,
                                    middleName: null,
                                    birthDate: null,
                                    gender: null,
                                    documentNumber: null,
                                    pinfl: null,
                                    issuedDate: null,
                                    expiryDate: null,
                                    birthPlace: null,
                                    permanentAddress: null,
                                    photoUrl: null,
                                    rawJson: null,
                                    deleted: false,
                                    createdBy: null,
                                    createdDate: null,
                                    lastModifiedBy: null,
                                    lastModifiedDate: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('my-id', null, {reload: 'my-id'});
                    }, function () {
                        $state.go('my-id');
                    });
                }]
            })
            .state('my-id.edit', {
                parent: 'my-id',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/my-id/my-id-dialog.html',
                        controller: 'MyIDDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['MyID', function (MyID) {
                                return MyID.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('my-id', null, {reload: 'my-id'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('my-id.delete', {
                parent: 'my-id',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/my-id/my-id-delete-dialog.html',
                        controller: 'MyIDDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['MyID', function (MyID) {
                                return MyID.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('my-id', null, {reload: 'my-id'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
