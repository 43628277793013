(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$location', '$window', 'Auth', 'Principal'];

    function PasswordController ($location, $window, Auth, Principal) {
        var vm = this;

        vm.changePassword = changePassword;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;

        Principal.identity().then(function(account) {
            vm.account = account;
        });

        function changePassword () {
            if (vm.password !== vm.confirmPassword) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {
                vm.doNotMatch = null;
                Auth.changePassword(vm.password).then(function () {
                    vm.error = null;
                    vm.success = 'OK';
                    logout();
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            }
        }

        function logout() {
            $location.path('/login').replace();
            Auth.logout();
            $window.location.reload();
        }
    }
})();
