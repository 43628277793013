(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbCardController', TkbCardController);

    TkbCardController.$inject = ['$state', '$window', 'TkbCard', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'ProfileIdByPhone'];

    function TkbCardController($state, $window, TkbCard, ParseLinks, AlertService, paginationConstants, pagingParams, ProfileIdByPhone) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openProfile = openProfile;
        vm.search = search;
        vm.clear = clear;
        vm.referenceId = null;
        vm.deleteByReferenceId = deleteByReferenceId;
        vm.searchQuery = {
            userPhone: pagingParams.userPhone,
            externalId: pagingParams.externalId,
            merchant: pagingParams.merchant,
            gateway: pagingParams.gateway,
            owner: pagingParams.owner,
            cardNumber: pagingParams.cardNumber,
            referenceId: pagingParams.referenceId,
            state: pagingParams.state,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            from: pagingParams.from,
            to: pagingParams.to
        };

        loadAll();

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url, '_blank');
            }
        }

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function loadAll () {
            TkbCard.query({
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                merchant: vm.searchQuery.merchant,
                gateway: vm.searchQuery.gateway,
                owner: vm.searchQuery.owner,
                cardNumber: vm.searchQuery.cardNumber,
                referenceId: vm.searchQuery.referenceId,
                state: vm.searchQuery.state,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tkbCards = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                userPhone: vm.searchQuery.userPhone,
                externalId: vm.searchQuery.externalId,
                merchant: vm.searchQuery.merchant,
                gateway: vm.searchQuery.gateway,
                owner: vm.searchQuery.owner,
                cardNumber: vm.searchQuery.cardNumber,
                referenceId: vm.searchQuery.referenceId,
                state: vm.searchQuery.state,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                from: vm.searchQuery.from,
                to: vm.searchQuery.to
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.searchQuery = {
                userPhone: null,
                externalId: null,
                merchant: 'ALL',
                gateway: 'ALL',
                owner: null,
                cardNumber: null,
                referenceId: null,
                state: -1,
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            };
            vm.transition();
        }

        function deleteByReferenceId() {
            TkbCard.deleteByReferenceId({referenceId: vm.referenceId}, onSuccess, onError);

            function onSuccess() {
                vm.referenceId = null;
                loadAll();
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
