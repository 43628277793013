(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionGateway', transactionGateway);

    var gateways = {
        yandex: {
            name: 'Yandex',
            style: 'btn yellow-mint btn-xs'
        },
        paynet: {
            name: 'Paynet',
            style: 'btn btn-xs green-seagreen'
        },
        vse_platezhi: {
            name: 'Vse Platezhi',
            style: 'btn btn-xs yellow-casablanca'
        },
        hermes: {
            name: 'Hermes',
            style: 'btn purple-sharp btn-xs'
        },
        tkb: {
            name: 'TKB',
            style: 'btn btn-xs yellow-casablanca'
        },
        undefined: {
            name: 'Undefined',
            style: 'btn btn-xs red-mint'
        }
    };

    function transactionGateway() {
        var directive = {
            restrict: 'EA',
            controller: TransactionGatewayController,
            controllerAs: 'vm',
            bindToController: true,
            template: '<button type="button" class="{{vm.style}}" style="pointer-events: none">' +
                '{{vm.name}}' +
                '</button>',
            scope: {
                gateway: '<'
            }
        };

        return directive;
    }

    function TransactionGatewayController() {
        var vm = this;

        setGateway();

        function setGateway() {
            var gateway = gateways[vm.gateway];
            if (gateway === undefined) {
                if (!vm.gateway) {
                    gateway = gateways[undefined];
                } else {
                    gateway = {
                        name: vm.gateway,
                        style: 'btn btn-xs red-mint'
                    }
                }
            }
            vm.name = gateway.name;
            vm.style = gateway.style;
        }
    }
})();
