(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileTransactionController', ProfileTransactionController);

    ProfileTransactionController.$inject = ['$scope', '$state', 'entity', 'ProfileTransaction', 'ProfileTransfer', 'ProfileTkbCard', 'ProfileTransactionAmount', 'ProfileTransferAmount', 'ParseLinks', 'AlertService', 'pagingParams', 'DateUtils', 'DataUtils'];

    function ProfileTransactionController($scope, $state, entity, ProfileTransaction, ProfileTransfer, ProfileTkbCard, ProfileTransactionAmount, ProfileTransferAmount, ParseLinks, AlertService, pagingParams, DateUtils, DataUtils) {
        var vm = this;

        vm.profile = entity;
        vm.itemsPerPage = 20;
        vm.activeTab = pagingParams.activeTab;
        vm.activateTab = activateTab;

        vm.predicateTranHist = pagingParams.predicateTranHist;
        vm.reverseTranHist = pagingParams.ascendingTranHist;
        vm.transitionTranHist = transitionTranHist;
        vm.loadAllTranHist = loadAllTranHist;

        vm.predicateTransfer = pagingParams.predicateTransfer;
        vm.reverseTransfer = pagingParams.ascendingTransfer;
        vm.transitionTransfer = transitionTransfer;
        vm.loadAllTransfers = loadAllTransfers;

        vm.predicateTkbCard = pagingParams.predicateTkbCard;
        vm.reverseTkbCard = pagingParams.ascendingTkbCard;
        vm.transitionTkbCard = transitionTkbCard;
        vm.loadAllTkbCard = loadAllTkbCard;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDirectAmount = DataUtils.getDirectAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.getCurrencyNameForClient = DataUtils.getCurrencyNameForClient;
        vm.getCurrencySymbolForClient = DataUtils.getCurrencySymbolForClient;

        loadAllTransfers();
        loadAllTranHist();
        loadAllTkbCard();
        getTotalTransactionAmount();
        getTotalTransferAmount();

        function loadAllTranHist() {
            ProfileTransaction.query({
                profileId: vm.profile.id,
                page: pagingParams.pageTranHist - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc')];
                if (vm.predicateTranHist !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksTranHist = ParseLinks.parse(headers('link'));
                vm.totalItemsTranHist = headers('X-Total-Count');
                vm.queryCountTranHist = vm.totalItemsTranHist;
                vm.transactionHistories = data;
                vm.pageTranHist = pagingParams.pageTranHist;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTranHist() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageTransfer: vm.pageTransfer,
                sortTransfer: vm.predicateTransfer + ',' + (vm.reverseTransfer ? 'asc' : 'desc'),
                pageTkbCard: vm.pageTkbCard,
                sortTkbCard: vm.predicateTkbCard + ',' + (vm.reverseTkbCard ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function loadAllTransfers() {
            ProfileTransfer.query({
                profileId: vm.profile.id,
                page: pagingParams.pageTransfer - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateTransfer + ',' + (vm.reverseTransfer ? 'asc' : 'desc')];
                if (vm.predicateTransfer !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksTransfer = ParseLinks.parse(headers('link'));
                vm.totalItemsTransfer = headers('X-Total-Count');
                vm.queryCountTransfer = vm.totalItemsTransfer;
                vm.transfers = data;
                vm.pageTransfer = pagingParams.pageTransfer;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTransfer() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageTransfer: vm.pageTransfer,
                sortTransfer: vm.predicateTransfer + ',' + (vm.reverseTransfer ? 'asc' : 'desc'),
                pageTkbCard: vm.pageTkbCard,
                sortTkbCard: vm.predicateTkbCard + ',' + (vm.reverseTkbCard ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function loadAllTkbCard() {
            ProfileTkbCard.query({
                profileId: vm.profile.id,
                page: pagingParams.pageTkbCard - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicateTkbCard + ',' + (vm.reverseTkbCard ? 'asc' : 'desc')];
                if (vm.predicateTkbCard !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.linksTkbCard = ParseLinks.parse(headers('link'));
                vm.totalItemsTkbCard = headers('X-Total-Count');
                vm.queryCountTkbCard = vm.totalItemsTkbCard;
                vm.tkbCards = data;
                vm.pageTkbCard = pagingParams.pageTkbCard;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionTkbCard() {
            $state.transitionTo($state.$current, {
                id: vm.profile.id,
                pageTranHist: vm.pageTranHist,
                sortTranHist: vm.predicateTranHist + ',' + (vm.reverseTranHist ? 'asc' : 'desc'),
                pageTransfer: vm.pageTransfer,
                sortTransfer: vm.predicateTransfer + ',' + (vm.reverseTransfer ? 'asc' : 'desc'),
                pageTkbCard: vm.pageTkbCard,
                sortTkbCard: vm.predicateTkbCard + ',' + (vm.reverseTkbCard ? 'asc' : 'desc'),
                activeTab: vm.activeTab
            });
        }

        function activateTab(tab) {
            vm.activeTab = tab;
        }

        function getTotalTransactionAmount() {
            ProfileTransactionAmount.query({
                profileId: vm.profile.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalTransactionAmount = data.totalAmount;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function getTotalTransferAmount() {
            ProfileTransferAmount.query({
                profileId: vm.profile.id
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.totalTransferAmount = data.totalTransferAmount;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }

})();
