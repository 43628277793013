(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsProfitController', StatsProfitController);

    StatsProfitController.$inject = ['AlertService', 'DateUtils', 'StatsProfitMargin'];

    function StatsProfitController(AlertService, DateUtils, StatsProfitMargin) {

        var vm = this;
        vm.search = search;
        var today = new Date(moment());
        var mon = '';
        var tue = '';
        var wed = '';
        var thu = '';
        var fri = '';
        var sat = '';
        var sun = '';
        var selectedDate = '';
        getDateRange(today);

        function search() {
            StatsProfitMargin.query(vm.searchQuery, onSuccess, onError);

            function onSuccess(data, headers) {
                callBarchsrt(data);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function callBarchsrt(data) {
            Highcharts.chart('barchart', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Total Transfer Profit'
                },
                xAxis: {
                    categories: ['Mon(' + mon + ')', 'Tue(' + tue + ')', 'Wed(' + wed + ')',
                        'Thu(' + thu + ')', 'Fri(' + fri + ')', 'Sat(' + sat + ')', 'Sun(' + sun + ')']
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'T o t a l ' + ' ' +   ' P r o f i t'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: -30,
                    verticalAlign: 'top',
                    y: 25,
                    floating: true,
                    backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: true
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{point.y}<br/>'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                        }
                    }
                },
                series: data
            });
        }


        function getDateRange(date) {
            selectedDate = date;
            var fromDate = new Date(moment(selectedDate).day(1).format("MM/DD/YYYY"));
            var toDate = new Date(moment(selectedDate).day(7).format("MM/DD/YYYY"));

            $('#datetimepicker').datetimepicker({weekStart: 1});
            var firstDateToShow = moment(selectedDate).day(1).format("MM/DD/YYYY");
            var lastDateToShow = moment(selectedDate).day(7).format("MM/DD/YYYY");
            $("#week").val(firstDateToShow + " ~ " + lastDateToShow);
            mon = moment(selectedDate).day(1).format("DD/MMM");
            tue = moment(selectedDate).day(2).format("DD/MMM");
            wed = moment(selectedDate).day(3).format("DD/MMM");
            thu = moment(selectedDate).day(4).format("DD/MMM");
            fri = moment(selectedDate).day(5).format("DD/MMM");
            sat = moment(selectedDate).day(6).format("DD/MMM");
            sun = moment(selectedDate).day(7).format("DD/MMM");
            callBarchsrt();

            vm.searchQuery = {
                fromDate: DateUtils.converterDateFormat(fromDate),
                toDate: DateUtils.converterDateFormat(toDate)
            };
            search();
        }

        $('#datetimepicker')
            .datetimepicker()
            .on('changeDate', function (ev) {
                var value = new Date($("#week").val());
                $('#datetimepicker').datetimepicker('hide');
                getDateRange(value);
            });


    }
})();
