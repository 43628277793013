(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('TransactionCheckStatus', TransactionCheckStatus);

    TransactionCheckStatus.$inject = ['$resource', 'DateUtils'];

    function TransactionCheckStatus($resource, DateUtils) {
        var resourceUrl = 'api/transactions/:id/check_status';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }

                    return data;
                }
            }

        });
    }
})();
