(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .factory('DataUtils', DataUtils);

    DataUtils.$inject = ['$window', '$filter', '$sce'];

    function DataUtils($window, $filter, $sce) {

        var service = {
            abbreviate: abbreviate,
            byteSize: byteSize,
            openFile: openFile,
            toBase64: toBase64,
            getDirectAmount: getDirectAmount,
            getDeductedAmount: getDeductedAmount,
            getSentAmount: getSentAmount,
            getResponse: getResponse,
            renderToHtml: renderToHtml,
            getCurrencyNameForClient: getCurrencyNameForClient,
            getCurrencySymbolForClient: getCurrencySymbolForClient
        };

        return service;

        function abbreviate(text) {
            if (!angular.isString(text)) {
                return '';
            }
            if (text.length < 30) {
                return text;
            }
            return text ? (text.substring(0, 15) + '...' + text.slice(-10)) : '';
        }

        function byteSize(base64String) {
            if (!angular.isString(base64String)) {
                return '';
            }

            function endsWith(suffix, str) {
                return str.indexOf(suffix, str.length - suffix.length) !== -1;
            }

            function paddingSize(base64String) {
                if (endsWith('==', base64String)) {
                    return 2;
                }
                if (endsWith('=', base64String)) {
                    return 1;
                }
                return 0;
            }

            function size(base64String) {
                return base64String.length / 4 * 3 - paddingSize(base64String);
            }

            function formatAsBytes(size) {
                return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' bytes';
            }

            return formatAsBytes(size(base64String));
        }

        function openFile(type, data) {
            $window.open('data:' + type + ';base64,' + data, '_blank', 'height=300,width=400');
        }

        function toBase64(file, cb) {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function (e) {
                var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                cb(base64Data);
            };
        }

        function getDirectAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.directAmount == null ? 0 : transaction.directAmount) + ' ' + currency;
        }

        function getDeductedAmount(transaction) {
            var number = $filter('number');
            var currency = getCurrencyNameForClient(transaction.customerClient);
            if (transaction.localCurrency != null){
                currency = transaction.localCurrency;
            }
            return number(transaction.amount) + ' ' + currency;
        }

        function getCurrencyNameForClient(customerClient) {
            var currency = 'ruble';
            if (customerClient == 'paygram_global') {
                currency = 'dollar';
            }
            return currency;
        }

        function getSentAmount(transaction) {
            var number = $filter('number');
            if (transaction.serviceCurrency != null){
                return number(transaction.amountInSom) + " " + transaction.serviceCurrency;
            } else if (transaction.gateway === 'vse_platezhi') {
                return number(transaction.amountInSom) + " ruble";
            } else if (transaction.serviceType == 'SERVICE_UZB_PHONE'
                || transaction.serviceType == 'SERVICE_UZB_INTERNET'
                || transaction.serviceType == 'SERVICE_UZB_UTILITY'
                || transaction.serviceType == 'SERVICE_KASH_PAYNET') {
                return number(transaction.amountInSom) + " so'm";
            } else if (transaction.serviceType == 'SERVICE_VOIP') {
                return number(transaction.voipEuro) + " euro";
            } else if (transaction.serviceType == 'SERVICE_VOIP_UZTEL') {
                return number(transaction.amountInSom) + " dollar";
            } else if (transaction.serviceType == 'SERVICE_RUS_PHONE') {
                return number(transaction.amountInSom) + (transaction.client == "010pay" ? " dollar" : " ruble");
            } else if (transaction.serviceType == 'SERVICE_KAZ_PHONE'
                || transaction.serviceType == 'SERVICE_KGZ_PHONE'
                || transaction.serviceType == 'SERVICE_TJK_PHONE'
                || transaction.serviceType == 'SERVICE_UKR_PHONE'
                || transaction.serviceType == 'SERVICE_ARM_PHONE'
                || transaction.serviceType == 'SERVICE_OK_CASH'
                || transaction.serviceType == 'SERVICE_EXPRESS_PAY'
                || transaction.serviceType == 'SERVICE_MOBILNIK') {
                return number(transaction.amountInSom) + " ruble";
            } else if (transaction.serviceType == 'SERVICE_PAYFORM_KAZ_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_KCELL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ALTEL'
                || transaction.serviceType == 'SERVICE_PAYFORM_KAZ_ACTIV'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_INDIGO'
                || transaction.serviceType == 'SERVICE_PAYFORM_TJK_VAVILONMOBILE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_NURTELECOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_KGZ_MEGACOM'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_BEELINE'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_MEGAFON'
                || transaction.serviceType == 'SERVICE_PAYFORM_RUS_TELE2'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_MTC'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_KYEVSTAR'
                || transaction.serviceType == 'SERVICE_PAYFORM_UKR_LIFE') {
                return number(transaction.amountInSom) + " dollar";
            } else if (transaction.serviceType == 'SERVICE_OK_CASH') {
                return number(transaction.amountInSom) + " rouble";
            } else if (transaction.serviceType == 'SERVICE_MYS_PHONE'
                || transaction.serviceType == 'SERVICE_NPL_PHONE'
                || transaction.serviceType == 'SERVICE_MMR_PHONE'
                || transaction.serviceType == 'SERVICE_IDN_PHONE'
                || transaction.serviceType == 'SERVICE_VIE_PHONE'
                || transaction.serviceType == 'SERVICE_KHM_PHONE'
                || transaction.serviceType == 'SERVICE_THA_PHONE'
                || transaction.serviceType == 'SERVICE_BGD_PHONE'
                || transaction.serviceType == 'SERVICE_LKA_PHONE'
                || transaction.serviceType == 'SERVICE_PHL_PHONE'
                || transaction.serviceType == 'SERVICE_IND_PHONE'
                || transaction.serviceType == 'SERVICE_PAK_PHONE'
                || transaction.serviceType == 'SERVICE_SGP_PHONE'
                || transaction.serviceType == 'SERVICE_CHN_PHONE'
                || transaction.serviceType == 'SERVICE_TUR_PHONE') {
                return number(transaction.amountInSom) + (transaction.status == 'SUCCESS' ? " dollar" : "");
            } else if (transaction.serviceType == 'SERVICE_THIS_BONUS') {
                return number(transaction.amountInSom) + " won";
            } else {
                return number(transaction.amount + transaction.usedMileage) + " " + getCurrencyNameForClient(transaction.customerClient);
            }
        }

        function getResponse(transaction) {
            var response = "";

            if (transaction.serviceType == 'SERVICE_UZB_PHONE'
                || transaction.serviceType == 'SERVICE_UZB_INTERNET'
                || transaction.serviceType == 'SERVICE_THIS_BONUS'
                || transaction.serviceType == 'SERVICE_VOIP_UZTEL'
                || transaction.serviceType == 'SERVICE_UZB_UTILITY'
                || transaction.serviceType == 'SERVICE_KASH_PAYNET') {
                response = transaction.autoModeResponse;
            } else if (transaction.serviceType == 'SERVICE_KOR_CARDPHONE_VOICE') {
                if (transaction.mangoAutoModeResponse != null) {
                    response = "Mango auto response: " + transaction.mangoAutoModeResponse;
                }
                if (transaction.thePayAutoModeResponse != null) {
                    response += "       010Pay auto response: " + transaction.thePayAutoModeResponse;
                }
            } else if (transaction.serviceType == 'SERVICE_KOR_INTL_CARD') {
                if (transaction.description.toLowerCase().indexOf('this') != -1) {
                    response = transaction.autoModeResponse;
                } else if (transaction.description.toLowerCase().indexOf('the+') != -1
                    || transaction.description.toLowerCase().indexOf('00301') != -1) {
                    response = transaction.thePayAutoModeResponse;
                } else {
                    response = transaction.mangoAutoModeResponse;
                }
            } else if (transaction.serviceType == 'SERVICE_VOIP') {
                response = transaction.yandexProcessPaymentResponse;
            } else {
                if (transaction.yandexRequestPaymentResponse != null) {
                    response += "Request payment response: " + transaction.yandexRequestPaymentResponse;
                }
                if (transaction.yandexProcessPaymentResponse != null) {
                    response += "       Process payment response: " + transaction.yandexProcessPaymentResponse;
                }
                if (transaction.autoModeResponse != null) {
                    response = transaction.autoModeResponse;
                }
            }

            return response;
        }

        function renderToHtml(value) {
            return $sce.trustAsHtml(value);
        }

        function getCurrencySymbolForClient(client) {
            if (client == null || client == undefined) {
                return '';
            }
            if (client == 'bozoraka' || client == 'mobpay') {
                return 'fa fa-won';
            } else if (client == 'paygram') {
                return 'fa fa-rub'
            } else if (client == 'paygram_global') {
                return 'fa fa-dollar'
            }
        }
    }
})();
