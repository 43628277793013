(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .filter('phoneNumber', phoneNumber);

    function phoneNumber() {
        return phoneNumberFilter;

        function phoneNumberFilter(phoneNumber) {
            if (phoneNumber.startsWith('010')) {
                return formatKoreanPhoneNumber(phoneNumber);
            } else if (phoneNumber.startsWith('m010')) {
                phoneNumber = phoneNumber.substring(1, 12);
                return formatKoreanPhoneNumber(phoneNumber);
            } else if (phoneNumber.startsWith('7')) {
                return formatRussianPhoneNumber(phoneNumber);
            }
            return phoneNumber;
        }

        function formatRussianPhoneNumber(phoneNumber) {
            return phoneNumber.substring(0, 1) + '-'
                + phoneNumber.substring(1, 4) + '-'
                + phoneNumber.substring(4, 7) + '-'
                + phoneNumber.substring(7, 9) + '-'
                + phoneNumber.substring(9, 11);
        }

        function formatKoreanPhoneNumber(phoneNumber) {
            return phoneNumber.substring(0, 3) + '-'
                + phoneNumber.substring(3, 7) + '-'
                + phoneNumber.substring(7, 11) + ''
        }
    }
})();
