(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsTransferReceiverController', StatsTransferReceiverController);

    StatsTransferReceiverController.$inject = ['$http', '$scope', '$state', '$window', 'StatsTransferReceiver', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'DateUtils', 'ProfileIdByPhone'];

    function StatsTransferReceiverController($http, $scope, $state, $window, StatsTransferReceiver, ParseLinks, AlertService, paginationConstants, pagingParams, DateUtils, ProfileIdByPhone) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.openProfile = openProfile;
        vm.excel = excel;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = {
            receiverCardNumber: pagingParams.receiverCardNumber,
            count: pagingParams.count,
            amount: pagingParams.amount,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
            fromZonedDate: pagingParams.fromZonedDate,
            toZonedDate: pagingParams.toZonedDate,
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.fromZonedDate = false;
        vm.datePickerOpenStatus.toZonedDate = false;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        if (!pagingParams.firstRun) {
            loadAll();
        }

        function getDateTimeFromPicker() {
            $('.input-daterange input').each(function() {
                if ($(this).attr('name') == 'fromZonedDate') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'toZonedDate') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
        }

        function openProfile(phoneNumber) {
            ProfileIdByPhone.get({phoneNumber: phoneNumber}, onSuccess);

            function onSuccess(data) {
                var url = $state.href('profile-detail', {id: data.profileId});
                $window.open(url,'_blank');
            }
        }

        function loadAll() {
            StatsTransferReceiver.query({
                receiverCardNumber: vm.searchQuery.receiverCardNumber,
                count: vm.searchQuery.count,
                amount: vm.searchQuery.amount,
                fromZonedDate: vm.searchQuery.fromZonedDate,
                toZonedDate: vm.searchQuery.toZonedDate,
                fromDate: vm.searchQuery.fromDate === '' ? null : vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate === '' ? null : vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'count(tkb.id)') {
                    result.push('count(tkb.id)');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.transferCount = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                receiverCardNumber: vm.searchQuery.receiverCardNumber,
                count: vm.searchQuery.count,
                amount: vm.searchQuery.amount,
                fromZonedDate: vm.searchQuery.fromZonedDate,
                toZonedDate: vm.searchQuery.toZonedDate,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                firstRun: false
            });
        }

        function search() {
            getDateTimeFromPicker();
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'count(tkb.id)';
            vm.reverse = true;
            vm.transition();
        }

        function excel() {
            getDateTimeFromPicker();
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.searchQuery.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.searchQuery.toDate);
            var data = (vm.searchQuery.receiverCardNumber !== null ? "receiverCardNumber=" + vm.searchQuery.receiverCardNumber + "&" : "")
                + "count=" + vm.searchQuery.count
                + "&amount=" + vm.searchQuery.amount
                + "&fromDate=" + vm.searchQuery.fromDate
                + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/stats-transfer-receiver-count-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'statistics-transfer-receiver-count-' + DateUtils.currentTimestamp() + '.xlsx');
            }).error(function () {
            });

        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'count(tkb.id)';
            vm.reverse = false;
            vm.searchQuery = {
                receiverCardNumber: null,
                count: 2,
                amount: 0,
                fromZonedDate: null,
                toZonedDate: null,
                fromDate: null,
                toDate: null,
            };
            vm.transferCount = [];
            vm.transition();
        }
    }
})();
