(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('ProfileTransaction', ProfileTransaction)
        .factory('ProfileTransfer', ProfileTransfer)
        .factory('ProfileTkbCard', ProfileTkbCard)
        .factory('ProfileTransactionAmount', ProfileTransactionAmount)
        .factory('ProfileTransferAmount', ProfileTransferAmount);

    ProfileTransaction.$inject = ['$resource'];

    function ProfileTransaction($resource) {
        var resourceUrl = 'api/profile-transaction-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileTransfer.$inject = ['$resource'];

    function ProfileTransfer($resource) {
        var resourceUrl = 'api/profile-transfer-history';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileTkbCard.$inject = ['$resource'];

    function ProfileTkbCard($resource) {
        var resourceUrl = 'api/profile-cards';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    ProfileTransactionAmount.$inject = ['$resource'];

    function ProfileTransactionAmount($resource) {
        var resourceUrl = 'api/profiles/:profileId/total-transaction-amount';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false }
        });
    }

    ProfileTransferAmount.$inject = ['$resource'];

    function ProfileTransferAmount($resource) {
        var resourceUrl = 'api/profiles/:profileId/total-transfer-amount';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false}
        });
    }

})();
