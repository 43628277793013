(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ConfigDialogController', ConfigDialogController);

    ConfigDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Config'];

    function ConfigDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Config) {
        var vm = this;

        vm.config = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.config.id !== null) {
                Config.update(vm.config, onSaveSuccess, onSaveError);
            } else {
                Config.save(vm.config, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:configUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


    }
})();
