(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsTransferReceiver', StatsTransferReceiver);

    StatsTransferReceiver.$inject = ['$resource', 'DateUtils'];

    function StatsTransferReceiver($resource) {
        var resourceUrl = 'api/stats-transfer-receiver-count';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
