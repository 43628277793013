(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbCardDetailController', TkbCardDetailController);

    TkbCardDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'TkbCard', 'Customer'];

    function TkbCardDetailController($scope, $rootScope, $stateParams, previousState, entity, TkbCard, Customer) {
        var vm = this;

        vm.tkbCard = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:tkbCardUpdate', function(event, result) {
            vm.tkbCard = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
