(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TkbTransferDeleteController',TkbTransferDeleteController);

    TkbTransferDeleteController.$inject = ['$uibModalInstance', 'entity', 'TkbTransfer'];

    function TkbTransferDeleteController($uibModalInstance, entity, TkbTransfer) {
        var vm = this;

        vm.tkbTransfer = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TkbTransfer.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
