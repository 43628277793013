/**
 * Created by Gayratjon Rayimjonov on 10/24/2017.
 */

(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsPeriodicalController', StatsPeriodicalController);

    StatsPeriodicalController.$inject = ['$http', '$scope', '$state', 'DateUtils', 'DataUtils', 'StatsPeriodical', 'StatsByCountry'];

    function StatsPeriodicalController($http, $scope, $state, DateUtils, DataUtils, StatsPeriodical, StatsByCountry) {
        var vm = this;
        vm.search = search;
        vm.excel = excel;
        vm.searchQuery = {};
        vm.stats = {
            totalTransactionsAmount : 0,
            largestTransactionsAmount: 0,
            transfersAmount:0,
            largestTransfersAmount:0,
            newUsers: 0,
            activeUsers: 0,
            failureTransactionCount: 0,
        };

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function search() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            StatsPeriodical.query(vm.searchQuery, onSuccess);
            StatsByCountry.query(vm.searchQuery, onSuccessByCountry);

            function onSuccess(data) {
                vm.stats = data;
            }

            function onSuccessByCountry(data) {
                createChartByCountry(data);
            }
        }

        function createChartByCountry(data) {
            var countryNames = data.map(function (value) {
                return value.countryName;
            });
            var successData = data.map(function (value) {
                return {
                    y: value.totalSuccessAmount,
                    count: value.successCount
                };
            });
            var failData = data.map(function (value) {
                return {
                    y: value.totalFailAmount,
                    count: value.failCount
                };
            });

            Highcharts.chart('statsByCountry', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Stats by Country'
                },
                xAxis: {
                    categories: countryNames
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'T o t a l ' + ' ' + ' A m o u n t'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: -30,
                    verticalAlign: 'top',
                    y: 25,
                    floating: true,
                    backgroundColor: '#ffffff',
                    shadow: true
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: <b>{point.y} ₽</b><br/>COUNT: <b>{point.count}</b>'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                        }
                    }
                },
                series: [{
                        name: "SUCCESS",
                        data: successData,
                        color: '#26C281'
                    },
                    {
                        name: "FAILURE",
                        data: failData,
                        color: '#e7505a'
                    }
                ]
            });
        }

        function excel() {
            vm.searchQuery.fromDate = DateUtils.converterDateTimeFormat(vm.fromDate);
            vm.searchQuery.toDate = DateUtils.converterDateTimeFormat(vm.toDate);
            var data = "client=" + vm.searchQuery.client + "&fromDate=" + vm.searchQuery.fromDate + '&toDate=' + vm.searchQuery.toDate;

            $http({
                url: 'api/stats-periodical-excel',
                method: 'POST',
                responseType: 'arraybuffer',
                data: data,
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                }
            }).success(function (data) {
                var blob = new Blob([data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                saveAs(blob, 'periodical-statistics-' + DateUtils.currentTimestamp() + '-' + vm.searchQuery.client + '.xlsx');
            }).error(function () {
            });
        }
    }
})();

