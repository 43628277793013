(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProviderCountryDeleteController',ProviderCountryDeleteController);

    ProviderCountryDeleteController.$inject = ['$uibModalInstance', 'entity', 'ProviderCountry'];

    function ProviderCountryDeleteController($uibModalInstance, entity, ProviderCountry) {
        var vm = this;

        vm.providerCountry = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ProviderCountry.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
