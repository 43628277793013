(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('provider-segment', {
            parent: 'entity',
            url: '/provider-segment?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.providerSegment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/provider-segment/provider-segments.html',
                    controller: 'ProviderSegmentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                name: null,
                providerName: null,
                partnerServiceId: null,
                accountFieldId: null,
                amountCharge: null,
                amountField: null,
                type: null,
                mode: null,
                status: null,
                firstRun: false
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        name: $stateParams.name,
                        providerName: $stateParams.providerName,
                        partnerServiceId: $stateParams.partnerServiceId,
                        accountFieldId: $stateParams.accountFieldId,
                        amountCharge: $stateParams.amountCharge,
                        amountField: $stateParams.amountField,
                        type: $stateParams.type,
                        mode: $stateParams.mode,
                        status: $stateParams.status,
                        firstRun: $stateParams.firstRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('providerSegment');
                    $translatePartialLoader.addPart('providerStatus');
                    $translatePartialLoader.addPart('providerSegmentType');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('provider-segment.edit', {
            parent: 'provider-segment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/provider-segment/provider-segment-dialog.html',
                    controller: 'ProviderSegmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProviderSegment', function(ProviderSegment) {
                            return ProviderSegment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('provider-segment', null, { reload: 'provider-segment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('provider-segment.new', {
            parent: 'provider-segment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/provider-segment/provider-segment-dialog.html',
                    controller: 'ProviderSegmentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                segmentField: null
                            }
                        }
                    }
                }).result.then(function() {
                    $state.go('provider-segment', null, { reload: 'provider-segment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
