(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GoogleAuthController', GoogleAuthController);

    GoogleAuthController.$inject = ['$state', '$window', '$location','Auth', 'GoogleAuth', 'Principal'];

    function GoogleAuthController ($state, $window, $location, Auth, GoogleAuth, Principal) {
        var vm = this;
        vm.step = 1;
        vm.logout = logout;
        vm.stepStyle = stepStyle;
        vm.back = back;
        vm.next = next;
        vm.userPassword = "";
        vm.passwordStatus = -1;
        vm.tfaVerifiedMessage = -1;
        vm.passwordVerified = false;
        vm.verifyPassword = verifyPassword;
        vm.isNextEnabled = isNextEnabled;
        vm.tfaNewStateEnabled = false;
        vm.isTfaEnabled = isTfaEnabled;
        vm.changeTfaState = changeTfaState;
        vm.newTfaVerified = false;
        vm.verifyTfa = verifyTfa;
        vm.save = save;

        Principal.identity().then(function(account) {
            vm.userName = account.login;
        });

        function verifyPassword() {
            vm.verifyingPassword = true;
            var gAuthData = {
                username: vm.userName,
                password: vm.userPassword
            };
            GoogleAuth.verifyPassword(gAuthData, onSuccess, onFailure);
            function onSuccess(data) {
                vm.verifyingPassword = false;
                if (angular.isDefined(data)) {
                    vm.passwordVerified = data.status;
                    if (data.status) {
                        vm.passwordStatus = 1;
                    } else {
                        vm.passwordStatus = 0;
                    }
                } else {
                    vm.passwordVerified = false;
                    vm.passwordStatus = 0;
                }
            }
            function onFailure() {
                vm.verifyingPassword = false;
                vm.passwordVerified = false;
                vm.passwordStatus = 0;
            }
        }

        function stepStyle(step) {
            if (step < vm.step) {
                return 'done';
            }
            if (step === vm.step) {
                return 'active';
            }
        }

        function back() {
            vm.step--;
        }

        function next() {
            vm.step++;
            if (vm.step === 2) {
                generateAuthKey();
            }
            if (vm.step === 3) {
                vm.newTfaVerified = false;
                vm.oneTimePassword = null;
            }
        }

        function isNextEnabled() {
            if (vm.step === 1 && (!vm.tfaNewStateEnabled || !vm.passwordVerified)) {
                return false;
            }
            if (vm.step === 2 && vm.loadingAuthKeys) {
                return false;
            }
            if (vm.step === 3 && !vm.newTfaVerified) {
                return false;
            }
            return true;
        }

        function isTfaEnabled() {
            return vm.tfaNewStateEnabled;
        }

        function changeTfaState(state) {
            vm.tfaNewStateEnabled = state;
        }

        function generateAuthKey() {
            vm.loadingAuthKeys = true;
            GoogleAuth.generateAuthKey(null, function(data) {
                    vm.tfaSecretKey = data.secretKey;
                    vm.tfaQrUrl = data.totpQrUrl;
                    vm.loadingAuthKeys = false;
                },
                function() {
                    vm.tfaSecretKey = null;
                    vm.tfaQrUrl = null;
                    vm.loadingAuthKeys = false;
                }
            );
        }

        function verifyTfa() {
            vm.verifyingAuthKeys = true;
            var gAuthData = {
                gAuthSecretKey: vm.tfaSecretKey,
                tfaQrUrl: vm.tfaQrUrl,
                oneTimePassword: vm.oneTimePassword
            };
            GoogleAuth.verifyAuthKey(gAuthData, onSuccess, onFailure);
            function onSuccess(data) {
                vm.verifyingAuthKeys = false;
                if (angular.isDefined(data)) {
                    vm.newTfaVerified = data.status;
                    if (vm.newTfaVerified) {
                        vm.tfaVerifiedMessage = 1;
                    } else {
                        vm.tfaVerifiedMessage = 0;
                    }
                } else {
                    vm.newTfaVerified = false;
                    vm.tfaVerifiedMessage = 0;
                }
            }
            function onFailure() {
                vm.verifyingAuthKeys = false;
                vm.newTfaVerified = false;
                vm.tfaVerifiedMessage = 0;
            }
        }

        function save() {
            vm.isSaving = true;
            var gAuthData = {
                newState: true,
                gAuthSecretKey: vm.tfaSecretKey,
                tfaQrUrl: vm.tfaQrUrl,
                oneTimePassword: vm.oneTimePassword
            };
            GoogleAuth.changeAuthKey(gAuthData, onSuccess, onFailure);
            function onSuccess() {
                vm.isSaving = false;
                logout();
            }
            function onFailure() {
                vm.isSaving = false;
                logout();
            }
        }

        function logout() {
            $location.path('/login').replace();
            Auth.logout();
            $window.location.reload();
        }
    }
})();
