(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('CustomerStats', CustomerStats)
        .factory('TotalTransfer', TotalTransfer)
        .factory('TotalPayment', TotalPayment);

    CustomerStats.$inject = ['$resource'];

    function CustomerStats($resource) {
        var resourceUrl = 'api/new-customer-count';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET', isArray: true}
        });
    }


    TotalTransfer.$inject = ['$resource'];

    function TotalTransfer($resource) {
        var resourceUrl = 'api/transfer-count';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET', isArray: true},
            'getNewUsers': {url:'api/transfer-new-user-count', method: 'GET', isArray: true}
        });
    }


    TotalPayment.$inject = ['$resource'];

    function TotalPayment($resource) {
        var resourceUrl = 'api/payment-count';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET', isArray: true},
            'getNewUsers': {url:'api/payment-new-user-count', method: 'GET', isArray: true}
        });
    }

})();
