(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UzbCardDeleteController',UzbCardDeleteController);

    UzbCardDeleteController.$inject = ['$uibModalInstance', 'entity', 'UzbCard'];

    function UzbCardDeleteController($uibModalInstance, entity, UzbCard) {
        var vm = this;

        vm.uzbCard = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            UzbCard.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
