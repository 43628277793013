(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tkb-transfer', {
            parent: 'entity',
            url: '/tkb-transfer?page&externalId&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.tkbTransfer.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfers.html',
                    controller: 'TkbTransferController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                userPhone: null,
                externalId: null,
                merchant: 'ALL',
                receiverOwner: null,
                receiverCardNumber: null,
                state: -1,
                creditState: -1,
                country: 'ALL',
                gateway: 'ALL',
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        userPhone: $stateParams.userPhone,
                        externalId: $stateParams.externalId,
                        merchant: $stateParams.merchant,
                        receiverOwner: $stateParams.receiverOwner,
                        receiverCardNumber: $stateParams.receiverCardNumber,
                        state: $stateParams.state,
                        creditState: $stateParams.creditState,
                        country: $stateParams.country,
                        gateway: $stateParams.gateway,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        from: $stateParams.from,
                        to: $stateParams.to
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tkbTransfer');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tkb-transfer-detail', {
            parent: 'tkb-transfer',
            url: '/tkb-transfer/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'bozorakaAdminApp.tkbTransfer.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfer-detail.html',
                    controller: 'TkbTransferDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tkbTransfer');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TkbTransfer', function($stateParams, TkbTransfer) {
                    return TkbTransfer.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tkb-transfer',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tkb-transfer-detail.edit', {
            parent: 'tkb-transfer-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfer-dialog.html',
                    controller: 'TkbTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TkbTransfer', function(TkbTransfer) {
                            return TkbTransfer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tkb-transfer.new', {
            parent: 'tkb-transfer',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfer-dialog.html',
                    controller: 'TkbTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                orderId: null,
                                externalId: null,
                                referenceId: null,
                                type: null,
                                senderOwner: null,
                                senderCardNumber: null,
                                receiverCardNumber: null,
                                amountRub: null,
                                amountUzs: null,
                                rate: null,
                                tkbCommissionAmount: null,
                                ubCommissionAmount: null,
                                spCommissionAmount: null,
                                tkbCommissionRate: null,
                                ubCommissionRate: null,
                                spCommissionRate: null,
                                state: null,
                                statusCode: null,
                                message: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tkb-transfer', null, { reload: 'tkb-transfer' });
                }, function() {
                    $state.go('tkb-transfer');
                });
            }]
        })
        .state('tkb-transfer.edit', {
            parent: 'tkb-transfer',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfer-dialog.html',
                    controller: 'TkbTransferDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TkbTransfer', function(TkbTransfer) {
                            return TkbTransfer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tkb-transfer', null, { reload: 'tkb-transfer' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tkb-transfer.delete', {
            parent: 'tkb-transfer',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-transfer/tkb-transfer-delete-dialog.html',
                    controller: 'TkbTransferDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TkbTransfer', function(TkbTransfer) {
                            return TkbTransfer.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tkb-transfer', null, { reload: 'tkb-transfer' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
