(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('UzbCardDetailController', UzbCardDetailController);

    UzbCardDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'UzbCard', 'Customer'];

    function UzbCardDetailController($scope, $rootScope, $stateParams, previousState, entity, UzbCard, Customer) {
        var vm = this;

        vm.uzbCard = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('bozorakaAdminApp:uzbCardUpdate', function(event, result) {
            vm.uzbCard = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
