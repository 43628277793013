(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsPartner', StatsPartner);

    StatsPartner.$inject = ['$resource', 'DateUtils'];

    function StatsPartner($resource, DateUtils) {
        var resourceUrl = 'api/stats-partner';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
