(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('paynet-mode', {
            parent: 'entity',
            url: '/paynet-mode',
            data: {
                authorities: ['ROLE_MANAGER'],
                pageTitle: 'paynetMode.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paynet-mode/paynet-modes.html',
                    controller: 'PaynetModeController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paynetMode');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('paynet-mode-detail', {
            parent: 'entity',
            url: '/paynet-mode/{id}',
            data: {
                authorities: ['ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.paynetMode.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/paynet-mode/paynet-mode-detail.html',
                    controller: 'PaynetModeDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('paynetMode');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('transactionMode');
                    $translatePartialLoader.addPart('transactionMode');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PaynetMode', function($stateParams, PaynetMode) {
                    return PaynetMode.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'paynet-mode',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('paynet-mode-detail.edit', {
            parent: 'paynet-mode-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paynet-mode/paynet-mode-dialog.html',
                    controller: 'PaynetModeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaynetMode', function(PaynetMode) {
                            return PaynetMode.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paynet-mode.new', {
            parent: 'paynet-mode',
            url: '/new',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paynet-mode/paynet-mode-dialog.html',
                    controller: 'PaynetModeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                paynetMode: null,
                                cardphoneMode: null,
                                mangoIntCardMode: null,
                                lastEdited: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('paynet-mode', null, { reload: 'paynet-mode' });
                }, function() {
                    $state.go('paynet-mode');
                });
            }]
        })
        .state('paynet-mode.edit', {
            parent: 'paynet-mode',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paynet-mode/paynet-mode-dialog.html',
                    controller: 'PaynetModeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PaynetMode', function(PaynetMode) {
                            return PaynetMode.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paynet-mode', null, { reload: 'paynet-mode' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('paynet-mode.delete', {
            parent: 'paynet-mode',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/paynet-mode/paynet-mode-delete-dialog.html',
                    controller: 'PaynetModeDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PaynetMode', function(PaynetMode) {
                            return PaynetMode.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('paynet-mode', null, { reload: 'paynet-mode' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
