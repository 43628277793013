(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('segment-field-option', {
            parent: 'entity',
            url: '/segment-field-option?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.segmentFieldOption.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/segment-field-option/segment-field-options.html',
                    controller: 'SegmentFieldOptionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                label: null,
                segmentFieldId: null,
                value: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        label: $stateParams.label,
                        segmentFieldId: $stateParams.segmentFieldId,
                        value: $stateParams.value
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('segmentFieldOption');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
            .state('segment-field-option.new', {
            parent: 'segment-field-option',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field-option/segment-field-option-dialog.html',
                    controller: 'SegmentFieldOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                label: null,
                                value: null,
                                position: null,
                                segmentFieldId: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('segment-field-option', null, { reload: 'segment-field-option' });
                }, function() {
                    $state.go('segment-field-option');
                });
            }]
        })

            .state('segment-field-option.edit', {
            parent: 'segment-field-option',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/segment-field-option/segment-field-option-dialog.html',
                    controller: 'SegmentFieldOptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['SegmentFieldOption', function(SegmentFieldOption) {
                            return SegmentFieldOption.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('segment-field-option', null, { reload: 'segment-field-option' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
