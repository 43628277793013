(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('DepositTransactionRedirectController', DepositTransactionRedirectController);

    DepositTransactionRedirectController.$inject = ['$stateParams', '$location', 'TransactionIdByNumber'];

    function DepositTransactionRedirectController($stateParams, $location, TransactionIdByNumber) {

        TransactionIdByNumber.get({transactionNo: $stateParams.transactionNo}, onSuccess);

        function onSuccess(data) {
            $location.path("transaction/" + data.transactionId);
        }
    }
})();
