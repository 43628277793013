(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('News', News)
        .factory('AllNews', AllNews);

    News.$inject = ['$resource', 'DateUtils'];

    function News ($resource, DateUtils) {
        var resourceUrl =  'api/news/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.pubDate = DateUtils.convertDateTimeFromServer(data.pubDate);
                        data.lastEdited = DateUtils.convertDateTimeFromServer(data.lastEdited);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    AllNews.$inject = ['$resource'];

    function AllNews ($resource) {
        var resourceUrl =  'api/all-news';
        return $resource(resourceUrl, {}, {
        });
    }
})();
