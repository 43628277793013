(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('HeaderController', HeaderController);

    HeaderController.$inject = ['$scope', '$state', '$location', '$window', 'Auth', 'Principal', 'ProfileService'];

    function HeaderController($scope, $state, $location, $window, Auth, Principal, ProfileService) {
        $scope.$on('$viewContentLoaded', function () {
            setTimeout(function () {
                Layout.initHeader();
            }, 0)
        });

        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.logout = logout;
        vm.$state = $state;

        function logout() {
            $location.path('/login').replace();
            Auth.logout();
            $window.location.reload();
        }
    }
})();
