(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('language-localization', {
            parent: 'entity',
            url: '/language-localization?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.languageLocalization.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/language-localization/language-localizations.html',
                    controller: 'LanguageLocalizationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                code: null,
                messageRu: null,
                messageUz: null,
                messageEn: null,
                firstRun: false
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        code: $stateParams.code,
                        messageRu: $stateParams.messageRu,
                        messageUz: $stateParams.messageUz,
                        messageEn: $stateParams.messageEn,
                        firstRun: $stateParams.firstRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('languageLocalization');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('language-localization.edit', {
            parent: 'language-localization',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/language-localization/language-localization-dialog.html',
                    controller: 'LanguageLocalizationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['LanguageLocalization', function(LanguageLocalization) {
                            return LanguageLocalization.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('language-localization', null, { reload: 'language-localization' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('language-localization.new', {
            parent: 'language-localization',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/language-localization/language-localization-dialog.html',
                    controller: 'LanguageLocalizationDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                code: null,
                                messageRu: null,
                                messageUz: null,
                                messageEn: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('language-localization', null, { reload: 'language-localization' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
