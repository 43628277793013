(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('tkb-payment', {
            parent: 'entity',
            url: '/tkb-payment?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.tkbPayment.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tkb-payment/tkb-payments.html',
                    controller: 'TkbPaymentController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                userPhone: null,
                externalId: null,
                account: null,
                state: -1,
                creditState: -1,
                gateway: 'ALL',
                transactionStatus: 'UNKNOWN',
                fromDate: null,
                toDate: null,
                from: null,
                to: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        userPhone: $stateParams.userPhone,
                        externalId: $stateParams.externalId,
                        account: $stateParams.account,
                        state: $stateParams.state,
                        creditState: $stateParams.creditState,
                        gateway: $stateParams.gateway,
                        transactionStatus: $stateParams.transactionStatus,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        from: $stateParams.from,
                        to: $stateParams.to
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tkbPayment');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('tkb-payment-detail', {
            parent: 'tkb-payment',
            url: '/tkb-payment/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'bozorakaAdminApp.tkbPayment.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/tkb-payment/tkb-payment-detail.html',
                    controller: 'TkbPaymentDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('tkbPayment');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'TkbPayment', function($stateParams, TkbPayment) {
                    return TkbPayment.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'tkb-payment',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('tkb-payment-detail.edit', {
            parent: 'tkb-payment-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-payment/tkb-payment-dialog.html',
                    controller: 'TkbPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TkbPayment', function(TkbPayment) {
                            return TkbPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tkb-payment.new', {
            parent: 'tkb-payment',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-payment/tkb-payment-dialog.html',
                    controller: 'TkbPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                serviceId: null,
                                orderId: null,
                                externalId: null,
                                referenceId: null,
                                account: null,
                                senderOwner: null,
                                senderCardNumber: null,
                                amount: null,
                                commissionAmount: null,
                                amountUzs: null,
                                ubCommissionAmount: null,
                                spCommissionAmount: null,
                                fieldJson: null,
                                state: null,
                                errorCode: null,
                                errorMessage: null,
                                createdBy: null,
                                createdDate: null,
                                lastModifiedBy: null,
                                lastModifiedDate: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('tkb-payment', null, { reload: 'tkb-payment' });
                }, function() {
                    $state.go('tkb-payment');
                });
            }]
        })
        .state('tkb-payment.edit', {
            parent: 'tkb-payment',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-payment/tkb-payment-dialog.html',
                    controller: 'TkbPaymentDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['TkbPayment', function(TkbPayment) {
                            return TkbPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tkb-payment', null, { reload: 'tkb-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('tkb-payment.delete', {
            parent: 'tkb-payment',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/tkb-payment/tkb-payment-delete-dialog.html',
                    controller: 'TkbPaymentDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['TkbPayment', function(TkbPayment) {
                            return TkbPayment.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('tkb-payment', null, { reload: 'tkb-payment' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
