(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('provider-category', {
            parent: 'entity',
            url: '/provider-category?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                pageTitle: 'bozorakaAdminApp.providerCategory.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/provider-category/provider-categories.html',
                    controller: 'ProviderCategoryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                name: null,
                countryId: null,
                status: null,
                providerId: null,
                firstRun: false
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        name: $stateParams.name,
                        countryId: $stateParams.countryId,
                        status: $stateParams.status,
                        providerId: $stateParams.providerId,
                        firstRun: $stateParams.firstRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('providerCategory');
                    $translatePartialLoader.addPart('providerStatus');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('provider-category.edit', {
            parent: 'provider-category',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/provider-category/provider-category-dialog.html',
                    controller: 'ProviderCategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ProviderCategory', function(ProviderCategory) {
                            return ProviderCategory.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('provider-category', null, { reload: 'provider-category' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('provider-category.new', {
            parent: 'provider-category',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/provider-category/provider-category-dialog.html',
                    controller: 'ProviderCategoryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {

                            }
                        }
                    }
                }).result.then(function() {
                    $state.go('provider-category', null, { reload: 'provider-category' });
                }, function() {
                    $state.go('provider-category');
                });
            }]
        })
    }

})();
