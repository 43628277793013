(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('StatsSearchController', StatsSearchController);

    StatsSearchController.$inject = ['$state', 'StatsSearch', 'AlertService', 'ParseLinks', 'paginationConstants', 'pagingParams'];

    function StatsSearchController($state, StatsSearch, AlertService, ParseLinks, paginationConstants, pagingParams) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.load = load;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.searchQuery = {
            field: pagingParams.field,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate
        };

        initDatePicker();

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function load() {
            StatsSearch.query({
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'query') {
                    result.push('query');
                }
                return result;
            }

            function onSuccess(data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.queries = data;
                // vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                field: vm.searchQuery.field,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,

            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'query';
            vm.reverse = true;
            // vm.transition();
            vm.load();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'query';
            vm.reverse = true;
            vm.searchQuery = {
                field: null,
                fromDate: null,
                toDate: null
            };
            // vm.transition();
        }
    }
})();
