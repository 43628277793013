(function () {
    'use strict';

    angular.module('bozorakaAdminApp')
        .directive('transactionService', transactionService);

    var services = {
        SERVICE_CHANGE_BALANCE: {
            name: 'Balance',
            style: 'btn grey-cascade btn-xs'
        },
        SERVICE_UZB_PHONE: {
            name: 'UzTel',
            style: 'btn green-meadow btn-xs'
        },
        SERVICE_UZB_INTERNET: {
            name: 'UzInt',
            style: 'btn red-sunglo btn-xs'
        },
        SERVICE_KOR_PHONE: {
            name: 'PreCall',
            style: 'btn blue-madison btn-xs'
        },
        SERVICE_KOR_CARDPHONE_VOICE: {
            name: 'PreCall',
            style: 'btn blue-madison btn-xs'
        },
        SERVICE_KOR_CARDPHONE_INTERNET: {
            name: 'PreData',
            style: 'btn yellow-crusta btn-xs'
        },
        SERVICE_KOR_INTL_CARD: {
            name: 'IntCard',
            style: 'btn blue-hoki btn-xs'
        },
        SERVICE_VOIP: {
            name: 'VoIP',
            style: 'btn purple-plum btn-xs'
        },
        SERVICE_VOIP_UZTEL: {
            name: 'VoIP UzTel',
            style: 'btn purple-plum btn-xs'
        },
        SERVICE_MARKET: {
            name: 'Market',
            style: 'btn yellow-gold btn-xs'
        },
        SERVICE_RUS_PHONE: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_KAZ_PHONE: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_KGZ_PHONE: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_TJK_PHONE: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_UKR_PHONE: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_ARM_PHONE: {
            name: 'AmTel',
            style: 'btn purple-studio btn-xs'
        },
        SERVICE_MYS_PHONE: {
            name: 'MysTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_NPL_PHONE: {
            name: 'NplTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_MMR_PHONE: {
            name: 'MmrTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_IDN_PHONE: {
            name: 'IdnTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_VIE_PHONE: {
            name: 'VieTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_KHM_PHONE: {
            name: 'KhmTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_THA_PHONE: {
            name: 'ThaTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_BGD_PHONE: {
            name: 'BgdTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_LKA_PHONE: {
            name: 'LkaTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_PHL_PHONE: {
            name: 'PhlTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_IND_PHONE: {
            name: 'IndTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_PAK_PHONE: {
            name: 'PakTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_SGP_PHONE: {
            name: 'SgpTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_CHN_PHONE: {
            name: 'ChnTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_TUR_PHONE: {
            name: 'TurTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_THIS_BONUS: {
            name: 'ThisBonus',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_PAYFORM_KAZ_TELE2: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_PAYFORM_KAZ_BEELINE: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_PAYFORM_KAZ_KCELL: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_PAYFORM_KAZ_ALTEL: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_PAYFORM_KAZ_ACTIV: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_PAYFORM_KGZ_BEELINE: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_PAYFORM_KGZ_MEGACOM: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_PAYFORM_KGZ_NURTELECOM: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_PAYFORM_TJK_BEELINE: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_PAYFORM_TJK_MEGAFON: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_PAYFORM_TJK_VAVILONMOBILE: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_PAYFORM_TJK_INDIGO: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_PAYFORM_RUS_BEELINE: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_PAYFORM_RUS_MEGAFON: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_PAYFORM_RUS_MTC: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_PAYFORM_RUS_TELE2: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_PAYFORM_UKR_MTC: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_PAYFORM_UKR_KYEVSTAR: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_PAYFORM_UKR_LIFE: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_HERMES_KAZ_TELE2: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_HERMES_KAZ_BEELINE: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_HERMES_KAZ_KCELL: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_HERMES_KAZ_ALTEL: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_HERMES_KAZ_ACTIV: {
            name: 'KzTel',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_HERMES_KGZ_BEELINE: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_HERMES_KGZ_MEGACOM: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_HERMES_KGZ_NURTELECOM: {
            name: 'KgTel',
            style: 'btn red-thunderbird btn-xs'
        },
        SERVICE_HERMES_TJK_BEELINE: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_HERMES_TJK_MEGAFON: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_HERMES_TJK_VAVILON_MOBILE: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_HERMES_TJK_INDIGO: {
            name: 'TjTel',
            style: 'btn green-seagreen btn-xs'
        },
        SERVICE_HERMES_RUS_BEELINE: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_HERMES_RUS_MEGAFON: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_HERMES_RUS_MTC: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_HERMES_RUS_TELE2: {
            name: 'RuTel',
            style: 'btn yellow-saffron btn-xs'
        },
        SERVICE_HERMES_UKR_MTC: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_HERMES_UKR_KYEVSTAR: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_HERMES_UKR_LIFE: {
            name: 'UkTel',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_OK_CASH: {
            name: 'OkCash',
            style: 'btn btn-warning btn-xs'
        },
        SERVICE_UZB_UTILITY: {
            name: 'UzbUtil',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_KASH_PAYNET: {
            name: 'Paynet',
            style: 'btn green-meadow btn-xs'
        },
        SERVICE_PAYMENT: {
            name: 'Payment',
            style: 'btn green-meadow btn-xs'
        },
        SERVICE_HERMES_PAYMENT: {
            name: 'Hermes',
            style: 'btn green-meadow btn-xs'
        },
        SERVICE_EXPRESS_PAY: {
            name: 'ExpressPay',
            style: 'btn green-sharp btn-xs'
        },
        SERVICE_MOBILNIK: {
            name: 'Mobilnik',
            style: 'btn blue-steel btn-xs'
        },
        SERVICE_UNKNOWN: {
            name: '',
            style: 'display-none'
        }
    };

    function transactionService() {

        var template = '<button type="button" class="{{vm.style}}" style="pointer-events: none">' +
            '{{vm.name}}' +
            '</button>';

        var directive = {
            restrict: 'EA',
            template: template,
            controller: TransactionServiceController,
            controllerAs: 'vm',
            scope: {
                type: '<'
            },
            bindToController: true
        };

        return directive;
    }

    function TransactionServiceController() {
        var vm = this;

        setService();

        function setService() {
            if (vm.type === undefined) {
                vm.type = 'SERVICE_UNKNOWN';
            }
            var service = services[vm.type];
            vm.name = service.name;
            vm.style = service.style;
        }
    }
})();
