(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('CustomerStatusLog', CustomerStatusLog);

    CustomerStatusLog.$inject = ['$resource'];

    function CustomerStatusLog ($resource) {
        var resourceUrl =  'api/customer-status-logs/all';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });
    }
})();
