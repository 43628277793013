(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ProfileNotificationDialogController', ProfileNotificationDialogController);

    ProfileNotificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'languages', 'CustomerNotification', 'Profile'];

    function ProfileNotificationDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, languages, CustomerNotification, Profile) {
        var vm = this;

        vm.profileId = $stateParams.id;
        vm.notification = entity;
        vm.languages = languages;
        vm.clear = clear;
        defineCustomerId();
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.language = {
            'en': {
                label: 'English',
                check: false,
                title: null,
                message: null
            },
            'ru': {
                label: 'Russian',
                check: false,
                title: null,
                message: null
            },
            'uz': {
                label: 'Uzbek',
                check: false,
                title: null,
                message: null
            },
            'tj': {
                label: 'Tоҷикӣ',
                check: false,
                title: null,
                message: null
            }
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function defineCustomerId() {
            Profile.get({id: vm.profileId}).$promise.then(function (profile) {
                vm.notification.customerId = profile.customerId;
            });
        }

        function save() {
            vm.isSaving = true;
            if (vm.notification.id !== null) {
                CustomerNotification.update(vm.notification, onSaveSuccess, onSaveError);
            } else {
                    vm.languages.forEach(function (languageKey) {
                    if (vm.language[languageKey].check === true) {
                        console.log(vm.language[languageKey].title);
                        vm.notification.title = vm.language[languageKey].title;
                        vm.notification.message = vm.language[languageKey].message;
                        vm.notification.language = languageKey;
                        vm.isSaving = true;
                        var notificationForLanguage = {
                            title: vm.language[languageKey].title,
                            message: vm.language[languageKey].message,
                            language: languageKey,
                            eventType: 'MESSAGE',
                            url: vm.notification.url,
                            readStatus: false,
                            status: vm.notification.status,
                            deleted: false,
                            pushDate: vm.notification.pushDate,
                            expiryDate: vm.notification.expiryDate,
                            customerId: vm.notification.customerId
                        }
                        CustomerNotification.save(notificationForLanguage, onSaveSuccess, onSaveError);
                    }
                });
            }
        }


        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:notificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;

        }

        function onSaveError() {
            vm.isSaving = false;
        }


        vm.datePickerOpenStatus.createdDate = false;
        vm.datePickerOpenStatus.lastModifiedDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
