(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TransactionDialogController', TransactionDialogController);

    TransactionDialogController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Transaction'];

    function TransactionDialogController($state, $timeout, $scope, $stateParams, $uibModalInstance, entity, Transaction) {
        var vm = this;

        vm.transaction = entity;
        vm.clear = clear;
        vm.save = save;
        vm.status = $stateParams.status;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.transaction.id !== null) {
                if (vm.status == 'fail') {
                    vm.transaction.status = 'FAILURE';
                } else if (vm.status == 'success') {
                    vm.transaction.status = 'SUCCESS';
                }
                Transaction.update(vm.transaction, onSaveSuccess, onSaveError);
                $state.go('request', null, {reload: 'request'});
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('bozorakaAdminApp:transactionUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
