(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('StatsMonthlyBalance', StatsMonthlyBalance)
        .factory('StatsMonthlyBalanceTotalAmount', StatsMonthlyBalanceTotalAmount);;

    StatsMonthlyBalance.$inject = ['$resource'];

    function StatsMonthlyBalance($resource) {
        var resourceUrl = 'api/stats-monthly-balance';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: true}
        });
    }

    StatsMonthlyBalanceTotalAmount.$inject = ['$resource'];

    function StatsMonthlyBalanceTotalAmount($resource) {
        var resourceUrl = 'api/stats-monthly-balance/total-amount';

        return $resource(resourceUrl, {}, {
            'get': { method: 'GET', isArray: false}
        });
    }
})();
